import { AxiosResponse } from "axios";
import { useCallback, useEffect, useState } from "react";
import { UserCheck } from "react-feather";
import { SidebarItemsType } from "../../components/sidebar/types";
import useAppDispatch from "../../redux/useAppDispatch";
import useAppSelector from "../../redux/useAppSelector";
import axios from "../../utils/api";
import useLog, { Action } from "../logs/useLogs";
import {
  clearData,
  fetchData as sliceFetchdata,
  checkFinished,
  selectProfileData,
  selectProfileStatus,
  selectFinished,
} from "./profileSlice";
import { AnswerRangeType, AnswerTextType, AnswerType } from "./types";

const useProfile = (force: boolean = false) => {
  const data = useAppSelector(selectProfileData);
  const status = useAppSelector(selectProfileStatus);
  const finished = useAppSelector(selectFinished);
  const [sidebarItems, setSidebarItems] = useState<SidebarItemsType>();
  const dispatch = useAppDispatch();

  const fetchData = useCallback(
    async (force: boolean = false): Promise<void> => {
      // FIXME: remove this.
      // await new Promise((resolve) => setTimeout(resolve, 5000));
      await dispatch(sliceFetchdata(force));
    },
    [dispatch]
  );

  useEffect(() => {
    fetchData(force);
  }, [fetchData, force]);

  const clear = async () => {
    dispatch(clearData());
  };

  const { send: sendLog } = useLog();

  const update = async (
    data: Array<AnswerType | AnswerTextType | AnswerRangeType>
  ) => {
    const response = await axios.put<
      any,
      AxiosResponse<any, any>,
      { Questions: Array<AnswerType | AnswerTextType | AnswerRangeType> }
    >("/user/profile", { Questions: data });
    const { Status } = response.data;
    if (Status !== "OK") {
      throw new Error(Status);
    } else {
      // fetch data for finished property only.
      sendLog(Action.QUESTION_EDITED, {}, undefined);
      await dispatch(checkFinished());
      return true;
    }
  };

  useEffect(() => {
    if (data) {
      let children: SidebarItemsType[] = [];
      children = data?.Groups.map((data, i) => {
        return {
          href: `/profile/${data.RouteName}`,
          title: data.Name,
          iconFont: data.IconName ?? "",
        } as SidebarItemsType;
      });
      // static menu user account
      children.unshift({
        href: "/profile/user",
        title: "Bruger",
        iconFont: "person_outline",
      } as SidebarItemsType);

      const items = {
        href: "/profile",
        icon: UserCheck,
        title: "Min profil",
        children,
      } as SidebarItemsType;

      setSidebarItems(items);
    }
  }, [data]);

  return { data, status, fetchData, clear, update, sidebarItems, finished };
};

export default useProfile;
