import { Box, Collapse, Typography } from "@mui/material";
import React, { useState } from "react";
import { ChevronDown, ChevronUp } from "react-feather";

const CollapseBox: React.FC<{ title: string }> = ({ title, children }) => {
  const [open, setOpen] = useState(true);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        backgroundColor: "rgba(9, 31, 44, 0.1)",
        borderRadius: "0 0 5px 5px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          minHeight: "63px",
          py: 2,
          px: 6,
          cursor: "pointer",
          bgcolor: "secondary.main",
          color: "secondary.contrastText",
          borderRadius: open ? "5px 5px 0 0" : "5px",
        }}
        onClick={() => setOpen(!open)}
      >
        <Typography
          sx={{
            color: "secondary.contrastText",
            fontWeight: 500,
            fontSize: "16px",
          }}
        >
          {title}
        </Typography>

        <Box>{open ? <ChevronUp /> : <ChevronDown />}</Box>
      </Box>
      <Box>
        <Collapse in={open}>
          <Box
            sx={{
              px: 6,
              py: 2,
            }}
          >
            {children}
          </Box>
        </Collapse>
      </Box>
    </Box>
  );
};

export default CollapseBox;
