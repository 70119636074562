import { CssBaseline } from "@mui/material";
import React from "react";
import { Outlet } from "react-router-dom";
import styled from "styled-components/macro";
import GlobalStyle from "../theme/GlobalStyle";

const Root = styled.div``;

const Auth: React.FC = ({ children }) => {
  return (
    <Root>
      <CssBaseline />
      <GlobalStyle />
      {children}
      <Outlet />
    </Root>
  );
};

export default Auth;
