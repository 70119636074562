import {
  Box,
  Button,
  Card,
  CardContent as MuiCardContent,
  CardContentProps,
  Grid,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import banner from "../../../assets/images/banner-2.jpg";
import ImageBanner from "../../../components/ImageBanner";
import MainContainer from "../../../components/MainContainer";
import UserInfoForm from "../components/UserInfoForm";
import UserAccountForm from "../components/UserAccountForm";
import UserAvatarForm from "../components/UserAvatarForm";
import useUser from "../useUser";
import { UserInfoType } from "../types";

type InfoProps = {
  label: JSX.Element | string;
};

const CardContent: React.FC<CardContentProps> = (props) => {
  return (
    <MuiCardContent
      {...props}
      sx={{
        p: {
          md: 8,
        },
      }}
    >
      {props.children}
    </MuiCardContent>
  );
};

const CardTitle: React.FC<any> = ({ children }) => {
  return (
    <Typography variant="h5" component="h2" mb={5}>
      {children}
    </Typography>
  );
};

const Info: React.FC<InfoProps> = ({ label, children }) => {
  return (
    <Box sx={{ fontWeight: 400, mb: 6 }}>
      <Box
        sx={{
          color: "rgba(9, 31, 44, 0.5)",
          fontSize: 11,
          mb: 2,
        }}
      >
        {label}
      </Box>
      {children}
    </Box>
  );
};

const CardUserAccount = () => {
  const { info } = useUser();
  const [edit, setEdit] = useState(false);
  return (
    <Card>
      <CardContent>
        <Box
          sx={{
            mb: 5,
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "space-between",
          }}
        >
          <CardTitle>Brugerkonto</CardTitle>
          <Button
            variant="contained"
            size="small"
            onClick={() => {
              setEdit(!edit);
            }}
            disabled={edit}
          >
            Rediger kodeord
          </Button>
        </Box>
        <Info label="Brugernavn">{info?.Username}</Info>
        {edit && info && (
          <UserAccountForm
            data={info}
            handleBack={() => {
              setEdit(false);
            }}
          />
        )}
        {!edit && (
          <>
            {/* TODO: edit action. */}
            <Info label={<>Kodeord</>}>******************</Info>
          </>
        )}
      </CardContent>
    </Card>
  );
};

const CardUserPhoto = () => {
  const { info } = useUser();
  return <>{info && <UserAvatarForm data={info} />}</>;
};

const UserInformation: React.FC<{
  info: UserInfoType | null;
}> = ({ info }) => {
  return (
    <Grid container columnSpacing={{ lg: 8, xs: 5 }}>
      <Grid item xl={5} lg={6} xs={12}>
        <Info label="Fornavn">{info?.FirstName ? info?.FirstName : "-"}</Info>
      </Grid>
      <Grid item xl={5} lg={6} xs={12}>
        <Info label="Efternavn">{info?.LastName ? info?.LastName : "-"}</Info>
      </Grid>
      <Grid item xs={12}></Grid>
      <Grid item xl={5} lg={6} xs={12}>
        <Info label="E-mail">{info?.Email}</Info>
      </Grid>
      <Grid item xl={5} lg={6} xs={12}>
        <Info label="Telefonnummer">
          {info?.Telephone ? info?.Telephone : "-"}
        </Info>
      </Grid>
      <Grid item xl={5} lg={6} xs={12}>
        <Info label="Vejnavn">{info?.Street ? info?.Street : "-"}</Info>
      </Grid>
      <Grid item xl={5} lg={6} xs={12}>
        <Info label="Husnummer">{info?.Houseno ? info?.Houseno : "-"}</Info>
      </Grid>
      <Grid item xl={5} lg={6} xs={12}>
        <Info label="Postnummer">{info?.ZipCode ? info?.ZipCode : "-"}</Info>
      </Grid>
      <Grid item xl={5} lg={6} xs={12}>
        <Info label="By">{info?.City ? info?.City : "-"}</Info>
      </Grid>
      <Grid item xl={5} lg={6} xs={12}>
        <Info label="Send email når der er nye boliger">
          {info?.Notifications ? "Ja" : "Nej"}
        </Info>
      </Grid>
    </Grid>
  );
};

const CardUserInformation = () => {
  const { info } = useUser(true);
  const [edit, setEdit] = useState(false);

  return (
    <Card>
      <CardContent>
        <Box
          sx={{
            mb: 5,
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "space-between",
          }}
        >
          <CardTitle>Bruger oplysninger</CardTitle>
          <Button
            variant="contained"
            size="small"
            onClick={() => {
              setEdit(!edit);
            }}
            disabled={edit}
          >
            Rediger oplysninger
          </Button>
        </Box>

        {edit && info && (
          <UserInfoForm
            data={info}
            handleBack={() => {
              setEdit(false);
            }}
          />
        )}

        {!edit && <UserInformation info={info} />}
      </CardContent>
    </Card>
  );
};

function UserAccountPage() {
  return (
    <MainContainer>
      <ImageBanner img={banner} />
      <Box height={{ lg: 40, xs: 20 }} />
      <Grid container spacing={{ lg: 8, xs: 5 }}>
        <Grid item lg={6} xs={12}>
          <CardUserAccount />
          <Box height={{ lg: 40, xs: 20 }} />
          {/* THIS HAS BEEN COMMENTED OUT UNTIL THE API CAN CONVERT 64 FROM AND TO BINARY */}
          <CardUserPhoto />
          <Box height={{ lg: 40, xs: 20 }} />
        </Grid>
        <Grid item lg={6} xs={12}>
          <CardUserInformation />
        </Grid>
      </Grid>
    </MainContainer>
  );
}

export default UserAccountPage;
