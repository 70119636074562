import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import { Navigate, useParams } from "react-router-dom";
import ForgotPasswordContent from "../components/ForgotPasswordContent";

function ForgotPasswordPage() {
  return (
    <>
      <Box
        sx={{
          display: "flex",
          minHeight: "100vh",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          py: 10,
          px: 5,
        }}
      >
        <ForgotPasswordContent />
      </Box>
    </>
  );
}

export default ForgotPasswordPage;
