import { RootState } from "../../redux/store";
import axios from "../../utils/api";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { UnitType } from "./type";

export interface UnitState {
  list: UnitType[];
  status: "idle" | "initialized" | "loading" | "failed";
}

const initialState: UnitState = {
  list: [],
  status: "idle",
};

export const fetchData = createAsyncThunk<
  UnitType[],
  boolean,
  { state: RootState }
>(
  "units/fetchData",
  async (_force: boolean = false) => {
    try {
      const response = await axios.get("/user/matchedunits/");
      return response.status === 200 && response.data
        ? response.data.Units
        : [];
    } catch (error) {}
  },
  {
    condition: (force: boolean = false, { getState }) => {
      const { units } = getState();
      if (units.status === "loading") {
        return false;
      }
      if (units.status === "initialized" && !force) {
        return false;
      }
    },
  }
);

export const unitsSlice = createSlice({
  name: "units",
  initialState,
  reducers: {
    // clear profile data.
    clearData: (state) => {
      state.list = [];
      state.status = "idle";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchData.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(fetchData.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchData.fulfilled, (state, action) => {
        if (action.payload) {
          state.status = "initialized";
        } else {
          state.status = "idle";
        }
        state.list = action.payload ?? [];
      });
  },
});

export const { clearData } = unitsSlice.actions;

export const selectUnitList = (state: RootState) => state.units.list;
export const selectUnitStatus = (state: RootState) => state.units.status;

export default unitsSlice.reducer;
