import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../redux/store";
import axios from "../../utils/api";
import { ProfileDataType } from "./types";

export interface ProfileState {
  data: null | ProfileDataType;
  status: "idle" | "initialized" | "loading" | "failed";
  finished: boolean;
}

const initialState: ProfileState = {
  data: null,
  status: "idle",
  finished: false,
};

export const fetchData = createAsyncThunk<
  ProfileDataType,
  boolean,
  { state: RootState }
>(
  "profile/fetchData",
  async (_force: boolean = false) => {
    try {
      const response = await axios.get("/user/profile/");
      return response.status === 200 && response.data ? response.data : null;
    } catch (error) {}
  },
  {
    condition: (force: boolean = false, { getState }) => {
      const { profile } = getState();
      if (profile.status === "loading") {
        return false;
      }
      if (profile.status === "initialized" && !force) {
        return false;
      }
    },
  }
);

export const checkFinished = createAsyncThunk<
  ProfileDataType,
  void,
  { state: RootState }
>("profile/checkfinished", async () => {
  try {
    const response = await axios.get("/user/profile/");
    return response.status === 200 && response.data ? response.data : null;
  } catch (error) {}
});

export const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    // clear profile data.
    clearData: (state) => {
      state.data = null;
      state.status = "idle";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchData.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(fetchData.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchData.fulfilled, (state, action) => {
        if (action.payload) {
          state.status = "initialized";
        } else {
          state.status = "idle";
        }
        state.data = action.payload;
        state.finished = action.payload?.ProfileFinished;
      })
      .addCase(checkFinished.fulfilled, (state, action) => {
        state.finished = action.payload?.ProfileFinished;
      });
  },
});

export const { clearData } = profileSlice.actions;

export const selectProfileData = (state: RootState) => state.profile.data;
export const selectProfileStatus = (state: RootState) => state.profile.status;
export const selectFinished = (state: RootState) => state.profile.finished;

export default profileSlice.reducer;
