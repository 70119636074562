import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import { Navigate, useParams } from "react-router-dom";
import ResetPasswordContent from "../components/ResetPasswordContent";

function ResetPasswordPage() {
  const { resetToken } = useParams();
  const [valid, setValid] = useState({
    Firstname: "",
    Valid: false,
    Invalid: false,
  });
  useEffect(() => {
    const fetchValidToken = async () => {
      const data = await fetch(process.env.REACT_APP_API_URL + "/user/verify", {
        method: "GET",
        headers: {
          Authorization: "Bearer " + resetToken,
        },
      });
      const json = await data.json();
      return json;
    };
    const result = fetchValidToken();
    result
      .then((data) => {
        setValid({
          Firstname: data.Firstname,
          Valid: data.Valid,
          Invalid: !data.Valid,
        });
      })
      .catch((data) => {
        setValid({
          Firstname: "",
          Valid: false,
          Invalid: true,
        });
      });
  }, []);
  return valid.Valid ? (
    <>
      <Box
        sx={{
          display: "flex",
          minHeight: "100vh",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          py: 10,
          px: 5,
        }}
      >
        <ResetPasswordContent firstname={valid.Firstname} token={resetToken} />
      </Box>
    </>
  ) : (
    <>
      {valid.Invalid ? (
        <Navigate
          to={"/auth"}
          state={{
            invalidToken:
              "Din token til nulstilling af adgangskode er udløbet.",
          }}
        />
      ) : (
        <></>
      )}
    </>
  );
}

export default ResetPasswordPage;
