import { Box } from "@mui/material";
import React from "react";
import styled from "styled-components";
import { UnitType } from "../type";

const ResponsiveIframe = styled("iframe")`
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  border: 0;
  border-radius: 10px;
`;

const UnitMap: React.FC<{ unit: UnitType }> = ({ unit }) => {
  return (
    <>
      {unit.GoogleMaps && (
        <Box
          sx={{
            position: "relative",
            pt: "35%",
            borderRadius: 10,
          }}
        >
          <ResponsiveIframe title="unit-map" src={unit.GoogleMaps} />
        </Box>
      )}
    </>
  );
};

export default UnitMap;
