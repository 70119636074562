import { AxiosResponse } from "axios";
import { useCallback } from "react";
import { v4 as uuidv4 } from "uuid";
import axios from "../../utils/api";

export enum Action {
  LOGIN = "login",
  LOGOUT = "logout",
  CHANGED_NAME = "changed_name",
  CHANGED_EMAIL = "changed_email",
  CHANGED_PASSWORD = "changed_password",
  NOTIFICATIONS_ON = "notifications_on",
  NOTIFICATIONS_OFF = "notifications_off",
  AUTOMATCHING_ON = "automatching_on",
  AUTOMATCHING_OFF = "automatching_off",
  UNIT_LIKED = "unit_liked",
  UNIT_DISLIKED = "unit_disliked",
  UNIT_BOOK_DISPLAY = "unit_book_display",
  UNIT_VIEWED = "unit_viewed",
  UNIT_CLOSED = "unit_closed",
  UNIT_NEXTMATCH = "unit_nextmatch",
  UNIT_COMMENTED = "unit_commented",
  QUESTION_EDITED = "question_edited",
}

type LogData = {
  Url?: String;
  UserID?: any;
  GroupID?: any;
};

type Log = {
  UnitID: number | null;
  DeviceID: string;
  SessionID: string;
  Action: Action;
  AddData: LogData;
};

const useLog = () => {
  const send = useCallback(
    (action: Action, data?: LogData, unitId?: number) => {
      try {
        const log: Log = {
          UnitID: unitId ?? null,
          Action: action,
          DeviceID: getDeviceId(),
          SessionID: getSessionId(),
          AddData: {
            Url: window.location.href,
            ...data,
          },
        };
        return axios
          .post<any, AxiosResponse<any, any>, Log>("/user/log", log)
          .then((response) => {
            if (response.status === 200) {
              // update units on some actions
              if (
                action === Action.UNIT_VIEWED ||
                action === Action.UNIT_LIKED ||
                action === Action.UNIT_DISLIKED
              ) {
                // fetchData(true);
              }
            }
          })
          .catch((er) => {
            return false;
          });
      } catch (error) {}
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const getDeviceId = (): string => {
    let deviceId = window.localStorage.getItem("deviceId");
    if (!deviceId) {
      deviceId = uuidv4();
      window.localStorage.setItem("deviceId", deviceId);
    }
    return deviceId;
  };

  const getSessionId = (): string => {
    let sessionId = window.localStorage.getItem("sessionId");

    if (sessionId) {
      // validate expiration
      if (!isSessionExpired()) return sessionId;
    }

    // create new session.
    sessionId = uuidv4();
    window.localStorage.setItem("sessionId", sessionId);

    setSessionExpire();

    return sessionId;
  };

  const isSessionExpired = (): boolean => {
    const sessionTime = window.localStorage.getItem("sessionTime");

    if (!sessionTime) {
      return false;
    }

    const currentTime = new Date().getTime();
    return Number(sessionTime) < currentTime;
  };

  const setSessionExpire = (): void => {
    // get timestamp
    const min = 120;
    const newTime = new Date(new Date().getTime() + min * 60000);
    window.localStorage.setItem("sessionTime", newTime.getTime().toString());
  };

  return { send };
};

export default useLog;
