import { Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useProfile } from "..";
import HelpPopup from "../components/HelpPopup";
import QuestionGroup from "../components/QuestionGroup";
import SectionTitle from "../components/SectionTitle";
import { GroupType } from "../types";

export const DynamicPage = () => {
  const { id } = useParams();
  const [group, setGroup] = useState<GroupType>();
  const { data, fetchData } = useProfile(true);

  useEffect(() => {
    // force data if id has been changed
    if (id) {
      fetchData(true);
    }
  }, [id, fetchData]);

  useEffect(() => {
    if (data) {
      setGroup(data.Groups.find((_group) => _group.RouteName === id));
    }
  }, [data, id]);

  return (
    <>
      <SectionTitle>{group?.Name}</SectionTitle>
      <Grid container mb={8}>
        <Grid item xl={6} lg={8}>
          <Typography fontWeight="500">{group?.Subtext}</Typography>
          {group?.RouteName !== "praeferencer" && (
            <Typography fontWeight="500" sx={{ color: "red", mt: 5 }}>
              * betyder at den <u>skal</u> udfyldes
            </Typography>
          )}
        </Grid>
      </Grid>
      {group && <QuestionGroup group={group} />}

      <HelpPopup />
    </>
  );
};
