import About from "./components/About";
import DashboardPage from "./components/DashboardPage";
import HowItWorks from "./components/HowItWorks";
import NeedHelp from "./components/NeedHelp";
import AuthLayout from "./layouts/Auth";
import DashboardLayout from "./layouts/Dashboard";
import GuestLayout from "./layouts/Guest";
import { AuthGuard, GuestGuard, LoginPage, RegisterPage } from "./modules/auth";
import ForgotPasswordPage from "./modules/password/pages/ForgotPasswordPage";
import ResetPasswordPage from "./modules/password/pages/ResetPasswordPage";
import { ProfilePageLayout, UserAccountPage } from "./modules/profile";
import { UnitPage, UnitsPage } from "./modules/units";

const routes = [
  {
    path: "/",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <DashboardPage />,
      },
      {
        path: "help",
        element: <NeedHelp />,
      },
      {
        path: "user-account",
        element: <UserAccountPage />,
      },
      {
        path: "profile/*",
        element: <ProfilePageLayout />,
        // children: [
        //   {
        //     path: "user",
        //     element: <ProfileUserPage />,
        //   },
        //   {
        //     path: "boligen",
        //     element: <HousingPage />,
        //   },
        //   {
        //     path: "economy",
        //     element: <EconomyPage />,
        //   },
        //   {
        //     path: "preferences",
        //     element: <PreferencesPage />,
        //   },
        //   {
        //     path: "about",
        //     element: <AboutPage />,
        //   },
        // ],
      },
      {
        path: "units",
        element: <UnitsPage />,
      },
      // {
      //   path: "units/:id",
      //   element: <UnitPage />,
      // },
    ],
  },
  {
    path: "/",
    element: <GuestLayout />,
    children: [
      {
        path: "how-it-works",
        element: <HowItWorks />,
      },
      {
        path: "about-nextkey",
        element: <About />,
      },
      {
        path: "units/:id",
        element: <UnitPage />,
      },
    ],
  },
  {
    path: "/auth",
    element: (
      <GuestGuard>
        <AuthLayout />
      </GuestGuard>
    ),
    children: [
      {
        path: "",
        element: <LoginPage />,
      },
      {
        path: "signup",
        element: <RegisterPage />,
      },
    ],
  },
  {
    path: "/password",
    element: (
      <GuestGuard>
        <AuthLayout />
      </GuestGuard>
    ),
    children: [
      {
        path: "forgot",
        element: <ForgotPasswordPage />,
      },
      {
        path: "reset/:resetToken",
        element: <ResetPasswordPage />,
      },
    ],
  },
];

export default routes;
