import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import counterReducer from "./slices/counter";
import { userReducer, profileReducer } from "../modules/profile";
import { unitsReducer } from "../modules/units";
import { authReducer } from "../modules/auth";

export const store = configureStore({
  reducer: {
    counter: counterReducer,
    user: userReducer,
    profile: profileReducer,
    units: unitsReducer,
    auth: authReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
