import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import PlayCircleFilledWhiteIcon from "@mui/icons-material/PlayCircleFilledWhite";
import StopCircleIcon from "@mui/icons-material/StopCircle";
import { Close } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  List,
  ListItem,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import styled from "styled-components";
import { useProfile } from "../../modules/profile";
import { useUser } from "../../modules/profile";
import useLog, { Action } from "../../modules/logs/useLogs";

const CloseButton = styled(IconButton)`
  position: absolute;
  right: -20px;
  top: -20px;
  background-color: #091f2c;
  color: #ffffff;
  &:hover {
    background-color: #204355 !important;
  }
`;

function NavbarAutoMatching() {
  const { data, finished, status } = useProfile();
  const { info, updateInfo } = useUser();
  function updateAutoMatch(value: boolean) {
    updateInfo({
      AutoMatch: value,
    });
  }
  const { send: sendLog } = useLog();

  const [openAutoMatchingDialog, setopenAutoMatchingDialog] =
    useState<boolean>(false);
  const handleAutoMatchDialog = () => setopenAutoMatchingDialog(true);

  const [openProfileMandatoryMissing, setopenProfileMandatoryMissing] =
    useState<boolean>(false);
  const handleMandatoryDialog = () => setopenProfileMandatoryMissing(true);
  if (finished !== undefined) {
    if (finished && !info?.AutoMatch) {
      updateAutoMatch(true);
      sendLog(Action.AUTOMATCHING_ON, {}, undefined);
    } else if (!finished && info?.AutoMatch) {
      updateAutoMatch(false);
      sendLog(Action.AUTOMATCHING_OFF, {}, undefined);
    }
  }
  return (
    <>
      <Dialog
        open={openProfileMandatoryMissing}
        onClose={() => setopenProfileMandatoryMissing(false)}
        PaperProps={{
          sx: {
            overflowY: "unset",
          },
        }}
      >
        <CloseButton onClick={() => setopenProfileMandatoryMissing(false)}>
          <Close />
        </CloseButton>
        <DialogTitle>
          Du mangler at udfylde følgende for at din profil er klar til
          automatisk matching
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Grid container>
              {data?.Groups.map((item, i) => (
                <>
                  {data.ProfileOpenGroups.map((opengrp, i) => (
                    <>
                      {opengrp.Name === item.Name ? (
                        <Grid item xs={12}>
                          <List>
                            <Typography
                              sx={{ fontWeight: "bold", fontSize: "14px" }}
                            >
                              {item.Name}
                            </Typography>

                            {item.Questions.map((item, i) => (
                              <>
                                {item.Mandatory ? (
                                  !item.AnsweredValues ? (
                                    <ListItem>{item.Prefix}</ListItem>
                                  ) : (
                                    ""
                                  )
                                ) : (
                                  ""
                                )}
                              </>
                            ))}
                          </List>
                        </Grid>
                      ) : (
                        ""
                      )}
                    </>
                  ))}
                </>
              ))}
            </Grid>
          </DialogContentText>
        </DialogContent>
      </Dialog>

      <Dialog
        open={openAutoMatchingDialog}
        onClose={() => setopenAutoMatchingDialog(false)}
        PaperProps={{
          sx: {
            overflowY: "unset",
          },
        }}
      >
        <CloseButton onClick={() => setopenAutoMatchingDialog(false)}>
          <Close />
        </CloseButton>
        <DialogTitle>Automatisk matching</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {finished && info?.AutoMatch ? (
              <>
                Din automatiske boligmatching, er nu aktiveret.
                <br /> Vi matcher din specifikke profil, med boliger som matcher
                dig ud fra dine præferencer.
                <br /> Algoritmen bliver hele tiden klogere, og tager dynamisk
                udgangspunkt i dine ønsker.
                <br /> Inden for 24 timer, vil du første gang modtage
                boligmatches
              </>
            ) : (
              <>
                Din automatiske boligmatching er slået fra.
                <br /> Dette skyldes at du mangler at udfylde din profil.
                <br /> Vi har indsat røde stjerner, på de punkter som er
                obligatoriske, for at vi kan matche dig med din potentielle
                drømmebolig.
                <br /> Så snart din profil er udfyldt, vil matchingen starte
                automatisk, og inden for 24 timer vil du modtage boligmatches
                som er relevante for dig.
              </>
            )}
          </DialogContentText>
        </DialogContent>
      </Dialog>

      {finished ? (
        <>
          {info?.AutoMatch ? (
            <Grid item>
              <List>
                <Button
                  onClick={handleAutoMatchDialog}
                  sx={{ color: "white", mr: 5 }}
                >
                  <InfoOutlinedIcon />
                  <Typography sx={{ color: "white", fontWeight: 400, ml: 2 }}>
                    AUTOMATISK MATCHING ER SLÅET TIL
                  </Typography>
                </Button>
                {/* <Button
                  onClick={() => updateAutoMatch(false)}
                  sx={{
                    backgroundColor: "red",
                    py: 0.5,
                    mr: 15,
                    pl: 2,
                  }}
                  variant="contained"
                >
                  <StopCircleIcon />
                  <Typography
                    sx={{
                      color: "white",
                      ml: 1,
                      fontWeight: 400,
                      fontSize: "14px",
                    }}
                  >
                    STOP
                  </Typography>
                </Button> */}
              </List>
            </Grid>
          ) : (
            <Grid item>
              <List>
                <Button
                  onClick={handleAutoMatchDialog}
                  sx={{ color: "white", mr: 5 }}
                >
                  <InfoOutlinedIcon />
                  <Typography sx={{ color: "white", fontWeight: 400, ml: 2 }}>
                    AUTOMATISK MATCHING ER SLÅET FRA
                  </Typography>
                </Button>
                {/* <Button
                  onClick={() => updateAutoMatch(true)}
                  sx={{
                    backgroundColor: "green",
                    py: 0.5,
                    mr: 15,
                    pl: 2,
                  }}
                  variant="contained"
                >
                  <PlayCircleFilledWhiteIcon />
                  <Typography
                    sx={{
                      color: "white",
                      ml: 1,
                      fontWeight: 400,
                      fontSize: "14px",
                    }}
                  >
                    START
                  </Typography>
                </Button> */}
              </List>
            </Grid>
          )}
        </>
      ) : (
        <Grid item>
          <List>
            <Button
              onClick={handleAutoMatchDialog}
              sx={{ color: "white", mr: 5 }}
            >
              <InfoOutlinedIcon />
              <Typography sx={{ color: "white", fontWeight: 400, ml: 2 }}>
                AUTOMATISK MATCHING
              </Typography>
            </Button>
            <Button
              onClick={handleMandatoryDialog}
              sx={{
                backgroundColor: "orange",
                py: 0.5,
                mr: 15,
                pl: 2,
              }}
              variant="contained"
            >
              <EditOutlinedIcon />
              <Typography
                sx={{
                  color: "white",
                  ml: 1,
                  fontWeight: 400,
                  fontSize: "14px",
                }}
              >
                Du mangler at færdiggøre din profil
              </Typography>
            </Button>
          </List>
        </Grid>
      )}
    </>
  );
}

export default NavbarAutoMatching;
