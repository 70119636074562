import { Box } from "@mui/material";
import React from "react";
import RegisterContent from "../components/RegisterContent";

function RegisterPage() {
  return (
    <>
      <Box
        sx={{
          display: "flex",
          minHeight: "100vh",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          py: 10,
          px: 5,
        }}
      >
        <RegisterContent />
      </Box>
    </>
  );
}

export default RegisterPage;
