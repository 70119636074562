import { Box, TextField, Typography } from "@mui/material";
import React, { useRef, useState } from "react";
import { AnswerTextType, QuestionType } from "../types";
import _ from "lodash";
import useProfile from "../useProfile";

type FieldTextProps = {
  question: QuestionType;
};

const FieldText: React.FC<FieldTextProps> = ({ question }) => {
  const [saving, setSaving] = useState(false);
  const { update } = useProfile();

  const updateRef = useRef(
    _.debounce(async (data: AnswerTextType) => {
      // TODO: compare with previous data.
      setSaving(true);
      await update([data]);
      setSaving(false);
    }, 2000)
  );

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    updateRef.current.cancel();
    const data: AnswerTextType = {
      ID: Number(question.ID),
      AnsweredValues: e.currentTarget.value,
    };
    updateRef.current(data);
  };

  return (
    <>
      {/* title and favorite icon */}
      <Box mb={3} sx={{ display: "flex", alignItems: "center" }}>
        <Typography fontWeight={500}>{question.Prefix}</Typography>
      </Box>

      <TextField
        // label={question.Prefix}
        multiline
        rows={5}
        fullWidth
        defaultValue={question?.AnsweredValue ?? ""}
        onChange={handleChange}
        variant="outlined"
        color="secondary"
        helperText={saving && "Saving..."}
        placeholder={question.Placeholder}
      />
    </>
  );
};

export default FieldText;
