import React from "react";
import ImageBanner from "./ImageBanner";
import banner from "../assets/images/banner-1.jpg";
import { Box, Grid, Paper, Typography } from "@mui/material";
import MainContainer from "./MainContainer";
import CardContact from "./CardContact";
import { ceo } from "../assets/UserImages/UserImageCollection";

function About() {
  return (
    <MainContainer>
      <ImageBanner img={banner} />
      <Box height={30} />

      <Grid container spacing={8}>
        <Grid item lg={8} xl={9}>
          <Paper sx={{ p: { lg: 10, xs: 5 }, height: "100%" }}>
            <Typography mb={4} variant="h3">
              Om Nextkey
            </Typography>

            <Typography mb={4}>
              Anne Mette Frost fik idéen til Nextkey, da hun arbejdede med
              ejendomsudvikling gennem flere år. Her opdagede hun et behov for
              større gennemsigtighed i en branche, der til tider har været kendt
              for det modsatte.
            </Typography>

            <Typography mb={4}>
              Det har nu ført til Nextkey, hvor missionen er at revolutionere
              den måde, at køber eller lejer finder frem til deres næste hjem.
              Nextkey vender boligmarkedet på hovedet, tager den boligsøgende i
              hånden og fører dem sikkert gennem processen, indtil de har nøglen
              til deres drømmebolig i hånden.
            </Typography>

            <Typography mb={4}>
              Nextkey gør det nemmere at være boligsøgende. Boligagenten gør det
              hårde arbejde og finder den rigtige bolig til dig, hvad enten du
              er på markedet for at købe eller leje. Derudover har vi
              verificeret både udlejere og sælgere, så du trygt kan skrive under
              på kontrakten.
            </Typography>

            <Typography mb={4}>
              Hos Nextkey har vi nogle kerneværdier, som kommer til udtryk
              gennem hele processen. Her finder du ingen speedsnakkende
              supersælgere, men i stedet rigtige mennesker, der kun har
              interesse i at hjælpe dig bedst muligt i din boligsøgning.
            </Typography>
          </Paper>
        </Grid>
        <Grid item lg={4} xl={3} xs={12}>
          <CardContact
            photo={ceo}
            name={"Anne Mette Frost"}
            title={"CEO"}
            email={"info@nextkey.dk"}
            phone={70605970}
          />
        </Grid>
      </Grid>
    </MainContainer>
  );
}

export default About;
