import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Alert,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Link,
  TextField,
  Typography,
} from "@mui/material";
import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import jsonp from "jsonp";
import {
  Link as RouterLink,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import * as Yup from "yup";
import useAuth from "../useAuth";

function RegisterForm() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [showPassword, setShowPassword] = useState(false);
  const [showAlert400, setShowAlert400] = useState(false);
  const navigate = useNavigate();
  const { signUp } = useAuth();
  return (
    <Formik
      initialValues={{
        email: String(
          searchParams.get("email") ? searchParams.get("email") : ""
        ),
        username: String(
          searchParams.get("username") ? searchParams.get("username") : ""
        ),
        firstname: String(
          searchParams.get("firstname") ? searchParams.get("firstname") : ""
        ),
        lastname: String(
          searchParams.get("lastname") ? searchParams.get("lastname") : ""
        ),
        phone: "",
        password: "",
        confirm: "",
        terms: false,
        submit: false,
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .email("Emailen skal indholde et @")
          .max(255)
          .required("Indtast din email"),
        confirm: Yup.string().max(255).required("Bekræft dit kodeord"),
        password: Yup.string().max(255).required("Indtast dit kodeord"),
        username: Yup.string().max(255).required("Indtast dit brugernavn"),
        phone: Yup.string().max(255).required("Indtast dit telefon nummer"),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        setShowAlert400(false);
        try {
          await signUp(
            values.email,
            values.password,
            values.username,
            values.firstname,
            values.lastname,
            values.phone
          );

          // close modal.
          navigate("/auth");
        } catch (error: any) {
          let message = error.message || "Something went wrong";

          if (error.response?.status === 400) {
            // show message for 400 instead error
            setShowAlert400(true);
          } else {
            setErrors({ submit: message });
          }

          setStatus({ success: false });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
        setFieldValue,
      }) => (
        <form noValidate onSubmit={handleSubmit}>
          {errors.submit && (
            <Alert sx={{ mb: 5, fontSize: 14 }} severity="warning">
              {errors.submit}
            </Alert>
          )}
          {showAlert400 && (
            <Alert sx={{ mb: 5, fontSize: 13 }} severity="info" icon={false}>
              Der findes allerede et medlem den e-mail adresse du forsøger at
              oprette et nyt medlem med. Gå til{" "}
              <Link component={RouterLink} to="/auth">
                login
              </Link>{" "}
              eller prøv igen med en anden e-mail adresse.
            </Alert>
          )}
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                type="text"
                name="firstname"
                label="Fornavn"
                value={values.firstname}
                error={Boolean(touched.firstname && errors.firstname)}
                fullWidth
                helperText={touched.firstname && errors.firstname}
                onBlur={handleBlur}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                type="text"
                name="lastname"
                label="Efternavn"
                value={values.lastname}
                error={Boolean(touched.lastname && errors.lastname)}
                fullWidth
                helperText={touched.lastname && errors.lastname}
                onBlur={handleBlur}
                onChange={handleChange}
              />
            </Grid>
          </Grid>
          <Box height={30} />
          <TextField
            type="tel"
            name="phone"
            label="Telefon"
            value={values.phone}
            error={Boolean(touched.phone && errors.phone)}
            fullWidth
            helperText={touched.phone && errors.phone}
            onBlur={handleBlur}
            onChange={handleChange}
          />
          <Box height={30} />
          <TextField
            type="email"
            name="email"
            label="E-mail"
            value={values.email}
            error={Boolean(touched.email && errors.email)}
            fullWidth
            helperText={touched.email && errors.email}
            onBlur={handleBlur}
            onChange={handleChange}
          />
          <Box height={30} />
          <TextField
            type="text"
            name="username"
            label="Brugernavn"
            value={values.username}
            error={Boolean(touched.username && errors.username)}
            fullWidth
            helperText={touched.username && errors.username}
            onBlur={handleBlur}
            onChange={handleChange}
          />
          <Box height={8} />
          <Box textAlign="right">
            <Button
              variant="text"
              color="primary"
              onClick={() => setShowPassword(!showPassword)}
              size="small"
              sx={{
                py: 1,
                fontWeight: 500,
              }}
            >
              {showPassword ? (
                <>
                  <VisibilityOff fontSize="small" />
                  &nbsp; Skjul kodeord
                </>
              ) : (
                <>
                  <Visibility fontSize="small" />
                  &nbsp; Vis kodeord
                </>
              )}
            </Button>
          </Box>
          <TextField
            type={showPassword ? "text" : "password"}
            name="password"
            label="Kodeord"
            value={values.password}
            error={Boolean(touched.password && errors.password)}
            fullWidth
            helperText={touched.password && errors.password}
            onBlur={handleBlur}
            onChange={handleChange}
          />
          <Box height={15} />
          <TextField
            type={showPassword ? "text" : "password"}
            name="confirm"
            label="Bekræft kodeord"
            value={values.confirm}
            error={Boolean(values.password !== values.confirm)}
            fullWidth
            onBlur={handleBlur}
            onChange={handleChange}
          />
          <Box height={24} />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            // color="primary"
            disabled={isSubmitting || !values.terms}
            size="large"
          >
            OPRET
          </Button>
          <Box height={12} />
          <FormControlLabel
            control={
              <Checkbox
                name="terms"
                color="primary"
                required={true}
                onChange={(e) => {
                  if (e.target.checked) {
                    setFieldValue("terms", true);
                  } else {
                    setFieldValue("terms", false);
                  }
                }}
              />
            }
            label={
              <Typography fontWeight={500} fontSize={12}>
                Jeg accepterer{" "}
                <a
                  target="_blank"
                  href="https://nextkey.dk/vilkaar/"
                  style={{ color: "inherit" }}
                  rel="noreferrer"
                >
                  vilkår og betingelser
                </a>
              </Typography>
            }
          />
        </form>
      )}
    </Formik>
  );
}

export default RegisterForm;
