import {
  Close,
  ThumbDown,
  ThumbDownOutlined,
  ThumbUp,
  ThumbUpOutlined,
} from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  TextField,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import useLog, { Action } from "../../logs/useLogs";
import { LikeDislikeType, UnitType } from "../type";
import useUnits from "../useUnits";

const CloseButton = styled(IconButton)`
  position: absolute;
  right: -20px;
  top: -20px;
  background-color: #091f2c;
  color: #ffffff;
  &:hover {
    background-color: #204355 !important;
  }
`;

type LikeDislikeButtonsProps = {
  unit: UnitType;
};

const LikeDislikeButtons: React.FC<LikeDislikeButtonsProps> = ({ unit }) => {
  const { likeDislike } = useUnits();
  const [sending, setSending] = useState<boolean>(false);
  const [data, setData] = useState<LikeDislikeType>({
    UserLikes: unit.UserLikes ? 1 : 0,
    UserDisLikes: unit.UserDisLikes ? 1 : 0,
    DislikeComment: unit.DislikeComment,
  });
  const [dataRender, setDataRender] = useState<LikeDislikeType>(data);

  const [openCommentDialog, setOpenCommentDialog] = useState<boolean>(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState<boolean>(false);
  const [confirmDialogAction, setConfirmDialogAction] = useState<
    "like" | "dislike"
  >("like");
  const [comment, setComment] = useState<string>(unit.DislikeComment);

  const refSending = useRef(false);

  useEffect(() => {
    if (refSending.current) {
      // send data.
      setSending(true);
      likeDislike(unit.UnitID, data)
        .then((res) => {
          if (res) {
            setDataRender(data);
          }
        })
        .finally(() => {
          setSending(false);
          refSending.current = false;
        });
    }
  }, [data, likeDislike, unit.UnitID]);

  const handleLike = () => {
    // if dislike is true, open confirm dialog to delete the message or not
    if (dataRender.UserDisLikes === 1) {
      setConfirmDialogAction("like");
      setOpenConfirmDialog(true);
    } else {
      refSending.current = true;
      setData({
        ...data,
        UserDisLikes: 0,
        UserLikes: dataRender.UserLikes === 1 ? 0 : 1,
      });
    }
  };

  const handleDislike = () => {
    // if dislike is true, open confirm dialog to delete the message or not
    if (dataRender.UserDisLikes === 1) {
      setConfirmDialogAction("dislike");
      setOpenConfirmDialog(true);
    } else {
      setOpenCommentDialog(true);
    }
  };

  const { send: sendLog } = useLog();

  const handleDislikeComment = () => {
    refSending.current = true;
    if (comment) {
      sendLog(Action.UNIT_COMMENTED, {}, unit.UnitID);
    }
    setData({
      UserLikes: 0,
      UserDisLikes: data.UserDisLikes === 1 ? 0 : 1,
      DislikeComment: comment,
    });
    setOpenCommentDialog(false);
  };

  const handleLikeConfirm = (delComment: boolean) => {
    refSending.current = true;
    setData({
      UserLikes: dataRender.UserLikes === 1 ? 0 : 1,
      UserDisLikes: 0,
      DislikeComment: delComment ? "" : dataRender.DislikeComment,
    });
  };

  const handleDislikeConfirm = (delComment: boolean) => {
    refSending.current = true;
    setData({
      UserLikes: 0,
      UserDisLikes: data.UserDisLikes === 1 ? 0 : 1,
      DislikeComment: delComment ? "" : dataRender.DislikeComment,
    });
    setOpenCommentDialog(false);
  };

  const handleConfirm = (confirm: boolean) => {
    if (confirmDialogAction === "like") {
      handleLikeConfirm(confirm);
    } else {
      handleDislikeConfirm(confirm);
    }
    if (confirm) {
      setComment("");
    }
    setOpenConfirmDialog(false);
  };

  return (
    <>
      <IconButton onClick={handleLike} disabled={sending}>
        {dataRender.UserLikes ? (
          <ThumbUp color="secondary" />
        ) : (
          <ThumbUpOutlined />
        )}
      </IconButton>
      <IconButton onClick={handleDislike} disabled={sending}>
        {dataRender.UserDisLikes ? (
          <ThumbDown color="secondary" />
        ) : (
          <ThumbDownOutlined />
        )}
      </IconButton>

      {/* comment dialog */}
      <Dialog
        open={openCommentDialog}
        onClose={() => setOpenCommentDialog(false)}
        PaperProps={{
          sx: {
            overflowY: "unset",
          },
        }}
      >
        <CloseButton onClick={() => setOpenCommentDialog(false)}>
          <Close />
        </CloseButton>
        <DialogTitle>Vi er kede af du dislikede</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Angiv en kommentar og hjælp os med at matche dig bedre boliger
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            type="text"
            fullWidth
            variant="standard"
            multiline
            rows={2}
            color="primary"
            defaultValue={dataRender.DislikeComment}
            onChange={(e) => {
              setComment(e.target.value);
            }}
          />
        </DialogContent>
        <DialogActions sx={{ px: 6, pb: 4 }}>
          <Button onClick={() => setOpenCommentDialog(false)}>Cancel</Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => handleDislikeComment()}
          >
            Gem
          </Button>
        </DialogActions>
      </Dialog>

      {/* confirm dialog */}
      <Dialog
        open={openConfirmDialog}
        onClose={() => setOpenConfirmDialog(false)}
        PaperProps={{
          sx: {
            overflowY: "unset",
          },
        }}
      >
        <CloseButton onClick={() => setOpenConfirmDialog(false)}>
          <Close />
        </CloseButton>
        <DialogTitle>Vil du slette kommentaren på denne unit?</DialogTitle>
        <DialogContent></DialogContent>
        <DialogActions sx={{ px: 6, pb: 4 }}>
          <Button onClick={() => handleConfirm(false)}>Nej</Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => handleConfirm(true)}
          >
            Ja
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default LikeDislikeButtons;
