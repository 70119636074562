import { ArrowBack, Web, Close } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  Chip,
  Grid,
  Link,
  Paper,
  Typography,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import MainContainer from "../../../components/MainContainer";
import useAppDispatch from "../../../redux/useAppDispatch";
import { useAuth } from "../../auth";
import { openSignupModal } from "../../auth/slice";
import { CardContent, CardTitle } from "../../profile/components/Card";
import Floorplan from "../components/Floorplan";
import LikeDislikeButtons from "../components/LikeDislikeButtons";
import UnitBanner from "../components/UnitBanner";
import UnitEconomyBox from "../components/UnitEconomyBox";
import UnitGallery from "../components/UnitGallery";
import UnitInfoBox from "../components/UnitInfoBox";
import UnitMap from "../components/UnitMap";
import UnitShimmer from "../components/UnitShimmer";
import UnitTermsBox from "../components/UnitTermsBox";
import { UnitType } from "../type";
import useUnits from "../useUnits";
import { getSingleUnit } from "../utils";
import styled from "styled-components";
import useLog, { Action } from "../../logs/useLogs";

const UnitPage = () => {
  const { bookDisplay } = useUnits();
  const { id } = useParams();
  const { isAuthenticated } = useAuth();
  const { sendLogViewed } = useUnits(true);
  const [unit, setUnit] = useState<UnitType>();
  const [loadingSingleUnit, setLoadingSingleUnit] = useState<boolean>(false);
  const [openDialog, setOpenDialog] = useState<boolean>(false);

  const dispatch = useAppDispatch();
  const handleOpenSignupModal = () => dispatch(openSignupModal());

  useEffect(() => {
    async function singleUnit() {
      setLoadingSingleUnit(true);
      const singleUnit = await getSingleUnit(Number(id));
      setUnit(singleUnit);
      setLoadingSingleUnit(false);
    }

    singleUnit();
  }, [id, isAuthenticated]);

  useEffect(() => {
    // log
    if (isAuthenticated) {
      sendLogViewed(Number(id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, isAuthenticated]);

  const floorplans = (unit: UnitType) => {
    return unit.Images.filter((image) => image.IsFloorplan);
  };
  const toolbar = (
    <>
      {unit && (
        <Box mb={6}>
          <Chip
            label={`${unit.AutomatchedPercentage?.toString()}% match`}
            color="primary"
            sx={{ mr: 4 }}
          />
          <Box sx={{ display: "inline-block" }} style={{ marginRight: 12 }}>
            <LikeDislikeButtons unit={unit} />
          </Box>
          <Box sx={{ display: "inline-block" }} style={{ marginRight: 12 }}>
            <Button
              variant="contained"
              onClick={() => {
                bookDisplay(unit.UnitID).then((res) => {
                  if (res) {
                    setOpenDialog(true);
                  }
                });
              }}
            >
              Book fremvisning
            </Button>
          </Box>
          {unit.Images && floorplans(unit).length ? (
            <Floorplan floorplans={floorplans(unit)} />
          ) : (
            <></>
          )}
        </Box>
      )}
    </>
  );

  const content = (
    <Box mb={8}>
      {/* address */}
      <Typography component="h2" variant="h3" mb={5}>
        {unit?.Address}
      </Typography>
      {/* description */}
      {unit && (
        <>
          <Typography
            component="div"
            dangerouslySetInnerHTML={{ __html: unit?.Description }}
          />
        </>
      )}
    </Box>
  );

  const signupBox = (
    <Box>
      <Card>
        <CardContent>
          <CardTitle mb={2}>Er du ikke medlem?</CardTitle>
          <Typography mb={4}>
            Opret dig som bruger og modtag selv forslag til boligmatches
          </Typography>
          <Button variant="contained" onClick={handleOpenSignupModal}>
            Opret bruger
          </Button>
        </CardContent>
      </Card>
    </Box>
  );

  const CloseButton = styled(IconButton)`
    position: absolute;
    right: -20px;
    top: -20px;
    background-color: #091f2c;
    color: #ffffff;
    &:hover {
      background-color: #204355 !important;
    }
  `;

  const { send: sendLog } = useLog();

  return (
    <MainContainer>
      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        PaperProps={{
          sx: {
            overflowY: "unset",
          },
        }}
      >
        <CloseButton onClick={() => setOpenDialog(false)}>
          <Close />
        </CloseButton>
        <DialogTitle>
          Dit ønske om at booke fremvisning er nu afsendt
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Du vil blive kontaktet inden længe.
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ px: 6, pb: 4 }}>
          <Button onClick={() => setOpenDialog(false)}>OK</Button>
        </DialogActions>
      </Dialog>
      <Grid container spacing={8}>
        {!isAuthenticated && (
          <Grid item lg={3} xs={12} order={{ xs: 1, lg: 2 }}>
            {signupBox}
          </Grid>
        )}
        <Grid
          item
          lg={isAuthenticated ? 12 : 9}
          xs={12}
          order={{ xs: 2, lg: 1 }}
        >
          {isAuthenticated && (
            <Box mb={8}>
              <Link
                component={NavLink}
                to="/units"
                onClick={() => {
                  sendLog(Action.UNIT_CLOSED, {}, unit?.UnitID);
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    fontWeight: 500,
                  }}
                >
                  <ArrowBack sx={{ mr: 2 }} />
                  Tilbage til bolig matches
                </Box>
              </Link>
            </Box>
          )}

          {/* content */}
          {!loadingSingleUnit && unit ? (
            <>
              <Paper
                sx={{
                  borderRadius: "10px",
                }}
              >
                <UnitBanner unit={unit} />
                <Box
                  p={{
                    xl: 16,
                    lg: 10,
                    md: 8,
                    xs: 5,
                  }}
                >
                  <>
                    {isAuthenticated ? (
                      unit.AutomatchedPercentage !== null ? (
                        toolbar
                      ) : (
                        <Box mb={6}>
                          {unit.Images && floorplans(unit).length ? (
                            <Floorplan floorplans={floorplans(unit)} />
                          ) : (
                            <></>
                          )}
                        </Box>
                      )
                    ) : (
                      <Box mb={6}>
                        {unit.Images && floorplans(unit).length ? (
                          <Floorplan floorplans={floorplans(unit)} />
                        ) : (
                          <></>
                        )}
                      </Box>
                    )}
                  </>

                  <Grid
                    container
                    columnSpacing={5}
                    mb={{ lg: 8, xl: 12 }}
                    sx={{ justifyContent: "space-between" }}
                  >
                    <Grid item xl={5} lg={6}>
                      <>{content}</>
                    </Grid>
                    <Grid item mb={6}>
                      <UnitInfoBox unit={unit} />
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    spacing={{ xl: 12, xs: 8 }}
                    mb={{ lg: 6, xl: 10, xs: 5 }}
                  >
                    <Grid item xs={12} md={6}>
                      <UnitEconomyBox unit={unit} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <UnitTermsBox unit={unit} />
                    </Grid>
                  </Grid>

                  <Box mb={{ xs: 8, xl: 12 }}>
                    <UnitGallery unit={unit} />
                  </Box>

                  <UnitMap unit={unit} />
                </Box>
              </Paper>
            </>
          ) : (
            <UnitShimmer />
          )}
        </Grid>
      </Grid>
    </MainContainer>
  );
};

export default UnitPage;
