import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";
import { StyledEngineProvider } from "@mui/styled-engine-sc";
import jssPreset from "@mui/styles/jssPreset";
import StylesProvider from "@mui/styles/StylesProvider";
import { create } from "jss";
import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { Provider } from "react-redux";
import { useRoutes } from "react-router-dom";
import { ThemeProvider } from "styled-components/macro";
import { AuthProvider } from "./modules/auth";
import { store } from "./redux/store";
import routes from "./routes";
import createTheme from "./theme";
import useTheme from "./theme/useTheme";

// @ts-ignore
const jss = create({
  ...jssPreset(),
  insertionPoint: document.getElementById("jss-insertion-point")!,
});

function App() {
  const content = useRoutes(routes);

  const { theme } = useTheme();

  return (
    <HelmetProvider>
      <Helmet>
        <title>Nextkey Medlems Portal</title>
        <meta
          name="description"
          content="Nextkey Medlems Portal er din personlige boligagent der automatisk matcher dig til boliger i vores omfattende boligdatabase."
        />
      </Helmet>
      <Provider store={store}>
        <StylesProvider jss={jss}>
          <StyledEngineProvider injectFirst>
            <MuiThemeProvider theme={createTheme(theme)}>
              <ThemeProvider theme={createTheme(theme)}>
                <AuthProvider>{content}</AuthProvider>
              </ThemeProvider>
            </MuiThemeProvider>
          </StyledEngineProvider>
        </StylesProvider>
      </Provider>
    </HelmetProvider>
  );
}

export default App;
