import { CssBaseline, Paper as MuiPaper } from "@mui/material";
import { spacing } from "@mui/system";
import React from "react";
import { Outlet } from "react-router-dom";
import styled from "styled-components/macro";
import Navbar from "../components/navbar/Navbar";
import { useAuth } from "../modules/auth";
import GlobalStyle from "../theme/GlobalStyle";
import DashboardLayout from "./Dashboard";

const AppContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  max-width: 100%;
`;

const Paper = styled(MuiPaper)(spacing);

const MainContent = styled(Paper)`
  flex: 1;
  background: ${(props) => props.theme.palette.background.default};

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    flex: none;
  }

  .MuiPaper-root .MuiPaper-root {
    box-shadow: none;
  }
`;

const Guest: React.FC = (props) => {
  const { isAuthenticated, isInitialized } = useAuth();

  return (
    <>
      {!isInitialized ? (
        <></>
      ) : (
        <>
          {isAuthenticated ? (
            <DashboardLayout {...props} />
          ) : (
            <div>
              <CssBaseline />
              <GlobalStyle />
              <AppContent>
                <Navbar onDrawerToggle={() => {}} />
                <MainContent p={5}>
                  {props.children}
                  <Outlet />
                </MainContent>
              </AppContent>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default Guest;
