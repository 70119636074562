import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import {
  Autocomplete,
  Box,
  Checkbox,
  Grid,
  Paper,
  Popper,
  PopperProps,
  TextField,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { AnswerType, OptionType, QuestionType } from "../types";
import useProfile from "../useProfile";
import FavoriteIconButton from "./FavoriteIconButton";
import _ from "lodash";
import { OptionChip } from "./FieldChips";

type FieldChipsSelectProps = {
  question: QuestionType;
};

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const FieldChipsSelect: React.FC<FieldChipsSelectProps> = ({ question }) => {
  const [isFavorite, setIsFavorite] = useState<boolean>(question.Favorite);
  const [selected, setSelected] = useState<Array<OptionType>>([]);
  const [init, setInit] = useState(false);
  const [minWidth, setMinWidth] = useState(0);
  const { update } = useProfile();

  const updateRef = useRef(
    _.debounce(async (data: AnswerType) => {
      // TODO: compare with previous data.
      await update([data]);
    }, 1000)
  );

  useEffect(() => {
    // set initial selected.
    if (question.Options) {
      setSelected(
        question.Options.reduce<Array<OptionType>>((filtered, option) => {
          if (option.Selected) {
            filtered.push(option);
          }
          return filtered;
        }, [])
      );
    }

    setIsFavorite(question.Favorite);
  }, [question]);

  useEffect(() => {
    if (init) {
      updateRef.current.cancel();
      const data: AnswerType = {
        ID: Number(question.ID),
        Favorite: isFavorite ? 1 : 0,
        AnsweredValues: selected.map((item) => {
          return {
            ID: item.Value,
            Value: true,
          };
        }),
      };
      updateRef.current(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFavorite, selected]);

  const handleFavorite = () => {
    setIsFavorite(!isFavorite);
    setInit(true);
  };

  const handleChange = (selected: OptionType[]) => {
    setSelected(selected);
    setInit(true);
  };

  const handleDelete = (item: OptionType) => {
    setSelected((prefValue) => prefValue.filter((_item) => item !== _item));
    setInit(true);
  };

  const paperComponent = useCallback(
    (props: React.HTMLAttributes<HTMLElement>) => (
      <Paper {...props} elevation={10} />
    ),
    []
  );

  return (
    <>
      {/* title and favorite icon */}
      <Box mb={3} sx={{ display: "flex", alignItems: "center" }}>
        <Box mr={1} lineHeight={1}>
          {/* favorite icon button */}
          <FavoriteIconButton isActive={isFavorite} onClick={handleFavorite} />
        </Box>

        <Typography fontWeight={500} display="inline-block">
          {question.Prefix}
        </Typography>
        {question.Mandatory ? (
          <Typography sx={{ color: "red", fontWeight: "bold", ml: 2 }}>
            *
          </Typography>
        ) : (
          <></>
        )}
      </Box>
      {question.Options && (
        <>
          <Grid container spacing={8}>
            <Grid item lg={4} xs={12} order={{ xs: 2, lg: 1 }}>
              {/* dropdown */}
              <Box sx={{ minHeight: { xs: 0, lg: 100 } }}>
                <Autocomplete
                  multiple
                  options={question.Options}
                  value={selected}
                  onChange={(event, newValue) => {
                    handleChange(newValue);
                  }}
                  size="medium"
                  disableCloseOnSelect
                  getOptionLabel={(option) => option.ShowValue}
                  ChipProps={{
                    color: "secondary",
                    sx: { borderRadius: "5px", display: "none" },
                  }}
                  // PopperComponent={popperComponent}
                  ListboxProps={{ style: { maxHeight: 250 } }}
                  PaperComponent={paperComponent}
                  renderOption={(props, option, { selected }) => (
                    <li {...props} key={option.Value}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                        color="secondary"
                      />
                      {option.ShowValue}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder={`${question.Placeholder} (${selected.length})`}
                      variant="standard"
                    />
                  )}
                  sx={{
                    ".MuiInputBase-root": {
                      paddingTop: 0,
                      paddingBottom: 2,
                    },
                  }}
                  // TODO: enable with dialog
                  disableClearable={true}
                />
              </Box>
            </Grid>
            <Grid item lg={7} xs={12} order={{ xs: 1, lg: 2 }}>
              {selected.map((item, i) => (
                <OptionChip
                  key={i}
                  ref={(ref) => {
                    if (ref) {
                      if (ref.offsetWidth > minWidth) {
                        setMinWidth(ref.offsetWidth);
                      }
                    }
                  }}
                  label={item.ShowValue}
                  variant="filled"
                  color="secondary"
                  onDelete={() => {
                    handleDelete(item);
                  }}
                  sx={{
                    justifyContent: "space-between",
                    minWidth: minWidth,
                  }}
                />
              ))}
            </Grid>
          </Grid>
          {/* selected */}
        </>
      )}
    </>
  );
};

export default FieldChipsSelect;

// <Box sx={{ minHeight: 300 }}>
//   <Autocomplete
//     multiple
//     id="checkboxes-tags-demo"
//     options={question.Options}
//     disableCloseOnSelect
//     getOptionLabel={(option) => option.ShowValue}
//     ChipProps={{ sx: { display: "none" } }}
//     open={open}
//     PopperComponent={({ style, ...props }) => (
//       <Popper
//         {...props}
//         style={{ ...style, height: 0 }}
//         placement="bottom-end"
//       />
//     )}
//     ListboxProps={{ style: { maxHeight: 250 } }}
//     renderOption={(props, option, { selected }) => (
//       <li {...props}>
//         <Checkbox
//           icon={icon}
//           checkedIcon={checkedIcon}
//           style={{ marginRight: 8 }}
//           checked={selected}
//         />
//         {option.ShowValue}
//       </li>
//     )}
//     style={{ width: 500 }}
//     renderInput={(params) => (
//       <TextField
//         {...params}
//         label={question.Prefix}
//         placeholder={question.Prefix}
//       />
//     )}
//   />
// </Box>;
