import { Box, Card, Grid } from "@mui/material";
import React from "react";
import Shimmer from "../../../components/Shimmer";
import { CardContent } from "../components/Card";

const QuestionsShimmer = () => {
  return (
    <>
      <Card>
        <CardContent>
          <Grid container>
            <Grid item xl={6} lg={8}>
              <Box sx={{ position: "relative", mb: 4 }}>
                <Shimmer></Shimmer>&nbsp;
              </Box>
              <Box sx={{ position: "relative", mb: 4 }}>
                <Shimmer></Shimmer>&nbsp;
              </Box>
              <Box sx={{ position: "relative" }}>
                <Shimmer></Shimmer>&nbsp;
              </Box>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
};

export default QuestionsShimmer;
