import { LockOutlined, Menu as MenuIcon } from "@mui/icons-material";

import {
  Alert,
  AppBar as MuiAppBar,
  Button,
  Grid,
  IconButton as MuiIconButton,
  List,
  ListItemButton as MuiListItemButton,
  ListItemButtonProps as MuiListItemButtonProps,
  Toolbar,
} from "@mui/material";
import React from "react";
import NavbarAutoMatching from "./NavbarAutoMatching";
import { Link, NavLink } from "react-router-dom";
import styled, { withTheme } from "styled-components/macro";
import NavbarUserDropdown from "./NavbarUserDropdown";
import logo from "../../assets/images/logo-white.png";
import { useAuth } from "../../modules/auth";
import useAppDispatch from "../../redux/useAppDispatch";
import { openLoginModal, openSignupModal } from "../../modules/auth/slice";

interface ListItemButtonProps extends MuiListItemButtonProps {
  component?: React.ElementType;
  to?: string;
}

const Brand = styled(MuiListItemButton)<{
  component?: React.ReactNode;
  to?: string;
}>`
  min-height: 56px;
  justify-content: center;
  cursor: pointer;
  flex-grow: 0;
`;

const BrandLogo = styled("img")`
  height: 32px;
`;

const AppBar = styled(MuiAppBar)`
  background: ${(props) => props.theme.header.background};
  color: ${(props) => props.theme.header.color};
`;

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;

const ListItemButton = styled(MuiListItemButton)<ListItemButtonProps>`
  display: inline-block;
  width: auto;
  padding-left: ${(props) => props.theme.spacing(3)};
  padding-right: ${(props) => props.theme.spacing(3)};
  font-weight: 600;
`;

type NavbarProps = {
  theme: {};
  onDrawerToggle: React.MouseEventHandler<HTMLElement>;
};

const Navbar: React.FC<NavbarProps> = ({ onDrawerToggle }) => {
  const { isAuthenticated } = useAuth();
  const dispatch = useAppDispatch();

  const handleOpenLoginModal = () => dispatch(openLoginModal());
  const handleOpenSignupModal = () => dispatch(openSignupModal());
  return (
    <React.Fragment>
      <AppBar position="sticky" elevation={0}>
        <Toolbar>
          <Grid container alignItems="center">
            {isAuthenticated && (
              <Grid
                item
                sx={{
                  display: { xs: "flex", md: "none" },
                }}
              >
                <IconButton
                  color="inherit"
                  aria-label="Open drawer"
                  onClick={onDrawerToggle}
                  size="large"
                >
                  <MenuIcon />
                </IconButton>
              </Grid>
            )}
            <Grid
              item
              sx={{
                display: {
                  xs: "block",
                  md: isAuthenticated ? "none" : "block",
                },
              }}
            >
              <Brand component={NavLink} to="/">
                <BrandLogo src={logo} />
              </Brand>
            </Grid>
            {isAuthenticated && (
              <>
                <Grid item sx={{ display: { xs: "none", md: "block" } }}>
                  <List>
                    <ListItemButton component={Link} to="/how-it-works">
                      HVORDAN DET VIRKER
                    </ListItemButton>
                    <ListItemButton component={Link} to="/about-nextkey">
                      OM NEXTKEY
                    </ListItemButton>
                  </List>
                </Grid>

                <Grid item xs />
                <NavbarAutoMatching />
                <Grid item>
                  <NavbarUserDropdown />
                </Grid>
              </>
            )}
            {!isAuthenticated && (
              <>
                <Grid item xs />
                <Grid item>
                  <Button
                    variant="contained"
                    sx={{ mr: 2 }}
                    onClick={handleOpenSignupModal}
                  >
                    Opret bruger
                  </Button>
                  <Button
                    variant="text"
                    endIcon={<LockOutlined />}
                    color="inherit"
                    onClick={handleOpenLoginModal}
                  >
                    Log ind
                  </Button>
                </Grid>
              </>
            )}
          </Grid>
        </Toolbar>
      </AppBar>
      {/* <Alert severity="error">
        Vigtig information til dig! Vi forbedrer vores avancerede match-værktøj,
        hvorfor automatisk match lige nu er slukket. Vi forventer snart at kunne
        tænde for det igen.
      </Alert> */}
    </React.Fragment>
  );
};

export default withTheme(Navbar);
