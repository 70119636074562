import React from "react";
import styled, { keyframes } from "styled-components";

const shimmerAnimation = keyframes`
  0% {background-position: -400px 0;}
  100% {background-position: 400px 0;}
`;

const ShimmerStyle = styled("div")`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #eeeeee;
  background-image: linear-gradient(
    to right,
    #eeeeee 40%,
    #dddddd 50%,
    #eeeeee 80%
  );
  background-repeat: repeat-x;
  background-size: 800px 100%;
  animation: ${shimmerAnimation} 2s infinite linear;
`;

const Shimmer: React.FC = ({ children }) => {
  return <ShimmerStyle>{children}</ShimmerStyle>;
};

export default Shimmer;
