import ImageBanner from "./ImageBanner";
import banner from "../assets/images/banner-1.jpg";
import { Grid, Box } from "@mui/material";
import CardStep from "./CardStep";
import MainContainer from "./MainContainer";
import styled from "styled-components/macro";

const Drawer = styled.div`
  ${(props) => props.theme.breakpoints.down("md")} {
    display: none;
  }
`;

function HowItWorks() {
  return (
    <MainContainer>
      <Drawer>
        <ImageBanner img={banner} />
      </Drawer>
      <Box height={30} />
      <Grid container spacing={8}>
        <CardStep
          step={1}
          title={"Start din boligsøgning"}
          paragraph={`Uanset om du skal flytte hjemmefra, drømmer om familieudvidelse eller pension i
storbyen, så starter din boligjagt et sted. Nextkey er et personligt og super
effektivt værktøj i din boligsøgning. Vi matcher dig med ledige boliger der passer
dine krav.`}
        />
        <CardStep
          step={2}
          title={"Du bliver kontaktet med boligmatches"}
          paragraph={`Du får tilsendt en email når der er matches klar til dig. Boligmatchene er
skræddersyede efter dine ønsker og præferencer. Du kan også få dig en snak
med en af Nextkeys kompetente boligagenter, som hjælper med din boligjagt.
`}
        />
        <CardStep
          step={3}
          title={"Du udvælger dine boligforslag"}
          paragraph={`Vores boligagenter kenderboligmarkedet, du kender dine egne kriterier. Du får
tilsendt boligmatches indtil vi rammer den helt rigtige bolig. Når du har udvalgt
hvilke boligmatches du vil se, arrangerer vi en fremvisning.
`}
        />

        <CardStep
          step={4}
          title={"Vi fremviser din (måske) kommende bolig"}
          paragraph={`Nextkey er der gennem hele processen. Vi fremviser de boliger du vil se og
hjælper dig med alle spørgsmål du måtte have. Nextkey er der for dig indtil du
står med nøglen til din nye bolig i hånden.`}
        />
      </Grid>
    </MainContainer>
  );
}

export default HowItWorks;
