import { ArrowCircleLeft, ArrowCircleRight } from "@mui/icons-material";
import { Box, Button, Icon, IconButton, Tab, Tabs } from "@mui/material";
import React, { useEffect, useState } from "react";
import { User } from "react-feather";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components/macro";
import useProfile from "../useProfile";

type LinkTabType = {
  activeclassname?: string;
  onClick?: () => void;
  to?: string;
  component?: typeof NavLink;
};

const LinkTabs = styled(Tabs)`
  .MuiTabs-indicator {
    display: none;
  }
`;

const LinkTab = styled(Tab)<LinkTabType>`
  min-height: 47px;
  margin-left: 1px;
  margin-right: 1px;
  flex-grow: 1;
  background: ${(props) => props.theme.profileNavbar.background};
  color: ${(props) => props.theme.profileNavbar.color};
  transition: all 0.2s;

  &:first-child {
    border-radius: 5px 0 0 5px;
  }

  &:last-child {
    border-radius: 0 5px 5px 0;
  }

  &.${(props) => props.activeclassname} {
    background: ${(props) => props.theme.profileNavbar.active.background};
    color: ${(props) => props.theme.profileNavbar.active.color};
  }
`;

type ItemType = {
  label: string;
  icon: React.ReactElement;
  to: string;
};

// const items: ItemType[] = [
//   { label: "Bruger", to: "/profile/user", icon: <User /> },
//   { label: "Boligen", to: "/profile/boligen", icon: <Home /> },
//   { label: "Økonomi", to: "/profile/economy", icon: <AttachMoneyIcon /> },
//   { label: "Præferencer", to: "/profile/Preferences", icon: <Star /> },
//   { label: "Lidt om dig", to: "/profile/about", icon: <Edit3 /> },
// ];

function Navbar() {
  const location = useLocation();
  const navigate = useNavigate();
  const [value, setValue] = useState(0);
  const [items, setItems] = useState<ItemType[]>([
    { label: "Bruger", to: "/profile/user", icon: <User /> },
  ]);
  const { data } = useProfile();

  useEffect(() => {
    // get dynamic items.
    if (data && data.Groups) {
      const dynamicItems: ItemType[] = data.Groups.map((group) => {
        return {
          label: group.Name,
          to: `/profile/${group.RouteName}`,
          icon: group.IconName ? <Icon>{group.IconName}</Icon> : <></>,
        } as ItemType;
      });
      setItems([
        {
          label: "Bruger",
          to: "/profile/user",
          // icon: <User />,
          icon: <Icon>person_outline</Icon>,
        },
        ...dynamicItems,
      ]);
    }
  }, [data]);

  useEffect(() => {
    // set value by route.
    const newValue = items.findIndex((item) => location.pathname === item.to);
    if (newValue !== -1) {
      setValue(newValue);
    }
  }, [location, items]);

  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleNext = () => {
    const target = value + 1;
    if (target >= items.length) {
      return;
    }
    navigate(items[target].to);
  };

  const handlePrev = () => {
    const target = value - 1;
    if (target < 0) {
      return;
    }
    navigate(items[target].to);
  };

  const prevButton = (
    <Box
      mr={4}
      sx={{
        display: { lg: "block", xs: "none" },
      }}
    >
      <Button
        variant="text"
        endIcon={<ArrowCircleLeft />}
        color="darkBlue"
        sx={{
          display: { lg: "flex", xs: "none" },
        }}
        onClick={handlePrev}
      >
        Tilbage
      </Button>
      <IconButton
        color="darkBlue"
        sx={{
          display: { lg: "none", xs: "inline-block" },
        }}
        onClick={handlePrev}
      >
        <ArrowCircleLeft />
      </IconButton>
    </Box>
  );

  const nextButton = (
    <Box
      ml={4}
      sx={{
        order: 2,
        display: { lg: "block", xs: "none" },
      }}
    >
      <Button
        variant="text"
        startIcon={<ArrowCircleRight />}
        color="darkBlue"
        sx={{
          display: { lg: "flex", xs: "none" },
        }}
        onClick={handleNext}
      >
        Næste
      </Button>
      <IconButton
        color="darkBlue"
        sx={{
          display: { lg: "none", xs: "inline-flex" },
        }}
        onClick={handleNext}
      >
        <ArrowCircleRight />
      </IconButton>
    </Box>
  );

  return (
    <Box
      sx={{
        display: { lg: "flex", xs: "block" },
        alignItems: "center",
      }}
    >
      {location.pathname !== "/profile/user" && prevButton}
      {location.pathname !== "/profile/lidtomdig" && nextButton}

      {/* navbar */}
      <Box
        sx={{
          flexGrow: 1,
        }}
      >
        <LinkTabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons={false}
        >
          {items.map((item, i) => (
            <LinkTab
              key={i}
              icon={item.icon}
              iconPosition="start"
              component={NavLink}
              label={
                <Box
                  sx={{ display: { xs: "none", md: "inline-block" }, ml: 1 }}
                >
                  {item.label}
                </Box>
              }
              to={item.to}
              activeclassname="active"
              sx={{
                minWidth: 40,
                px: {
                  xs: 0,
                  md: 3,
                },
                "> .material-icons": {
                  mr: 0,
                },
              }}
            />
          ))}
        </LinkTabs>
      </Box>
    </Box>
  );
}

export default Navbar;
