import { Grid, Home, Phone } from "react-feather";
import { Unseen } from "../../modules/units";
import { SidebarItemsType } from "./types";

const pagesSection = [
  {
    href: "/",
    icon: Grid,
    title: "Forside",
  },
  // {
  //   href: "/profile",
  //   icon: UserCheck,
  //   title: "Min profil",
  //   children: [
  //     {
  //       href: "/profile/user",
  //       title: "Bruger",
  //       icon: User,
  //     },
  //     {
  //       href: "/profile/boligen",
  //       title: "Boligen",
  //       icon: Home,
  //     },
  //     {
  //       href: "/profile/economy",
  //       title: "Økonomi",
  //       icon: AttachMoneyIcon,
  //     },
  //     {
  //       href: "/profile/Preferences",
  //       title: "Præferencer",
  //       icon: Star,
  //     },
  //     {
  //       href: "/profile/about",
  //       title: "Lidt om dig",
  //       icon: Edit3,
  //     },
  //   ],
  // },
  {
    href: "/units",
    icon: Home,
    title: "Boligmatches",
    badge: (
      <>
        <Unseen />
      </>
    ),
  },
  {
    href: "/help",
    icon: Phone,
    title: "Brug for hjælp?",
  },
] as SidebarItemsType[];

const navItems = [
  {
    title: "",
    pages: pagesSection,
  },
];

export default navItems;
