import {
  Avatar,
  IconButton as MuiIconButton,
  Menu,
  MenuItem as MuiMenuItem,
  MenuItemProps,
  Tooltip,
} from "@mui/material";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components/macro";
import { useAuth } from "../../modules/auth";
import { selectUserInfo, useProfile, useUser } from "../../modules/profile";

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;

interface MenuItemButtonProps extends MenuItemProps {
  component?: React.ElementType;
  to?: string;
}

const MenuItem = styled(MuiMenuItem)<MenuItemButtonProps>`
  border-bottom: solid 0.5px #f1eee7;
  padding-top: 12px;
  padding-bottom: 12px;
  font-weight: 400;
  &:last-child {
    border-bottom: none;
  }

  > a {
    display: block;
  }
`;

function NavbarUserDropdown() {
  const { data } = useProfile();
  const { signOut } = useAuth();
  const { avatar } = useUser();
  const [anchorMenu, setAnchorMenu] = useState<any>(null);
  const navigate = useNavigate();

  const toggleMenu = (event: React.SyntheticEvent) => {
    setAnchorMenu(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorMenu(null);
  };

  const handleSignOut = async () => {
    await signOut();
    navigate("/auth");
  };
  return (
    <React.Fragment>
      <Tooltip title="Account">
        <IconButton
          aria-owns={Boolean(anchorMenu) ? "menu-appbar" : undefined}
          aria-haspopup="true"
          onClick={toggleMenu}
          color="inherit"
          size="large"
        >
          {/* TODO: user photo/avatar */}
          <Avatar src={avatar} />
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorMenu}
        open={Boolean(anchorMenu)}
        onClose={closeMenu}
        MenuListProps={{
          style: {
            padding: 0,
          },
        }}
        sx={{
          mt: -2,
        }}
      >
        <MenuItem component={Link} to="/user-account" onClick={closeMenu}>
          Brugerkonto &amp; Ændre kodeord
        </MenuItem>
        {data?.ProfileFinished ? (
          ""
        ) : (
          <MenuItem
            component={Link}
            onClick={closeMenu}
            to={"/profile/" + data?.ProfileOpenGroups[0]?.RouteName}
          >
            Gør din medlemsprofil færdig
          </MenuItem>
        )}
        <MenuItem onClick={handleSignOut}>Log ud</MenuItem>
      </Menu>
    </React.Fragment>
  );
}

export default NavbarUserDropdown;
