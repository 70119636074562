import { Close } from "@mui/icons-material";
import { Box, IconButton, Modal } from "@mui/material";
import React from "react";
import useAppDispatch from "../../../redux/useAppDispatch";
import useAppSelector from "../../../redux/useAppSelector";
import { closeLoginModal, selectOpenLoginModal } from "../slice";
import LoginContent from "./LoginContent";

const LoginModal = () => {
  const isOpen = useAppSelector(selectOpenLoginModal);
  const dispatch = useAppDispatch();

  const handleClose = () => dispatch(closeLoginModal());

  return (
    <Modal open={isOpen} onClose={handleClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 1000,
          maxWidth: "100%",
        }}
      >
        <IconButton
          sx={{
            position: "absolute",
            right: "-20px",
            top: "-20px",
            backgroundColor: "#091f2c",
            color: "#ffffff",
            "&:hover": {
              backgroundColor: "#204355 !important",
            },
          }}
          onClick={handleClose}
        >
          <Close />
        </IconButton>
        <LoginContent />
      </Box>
    </Modal>
  );
};

export default LoginModal;
