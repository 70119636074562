import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Chip,
  styled,
  Theme,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { UnitType } from "../type";
import useUnits from "../useUnits";
import { getFrontThumbnail } from "../utils";
import LikeDislikeButtons from "./LikeDislikeButtons";

const CardOverlay = styled(Box)`
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.1);
  z-index: 2 !important;
`;

const CardRibbon = styled(Box)`
  position: absolute;
  width: 120px;
  padding: 5px;
  left: 0;
  top: 0;
  font-size: 13px;
  transform: translateX(-30%) translateY(-100%) rotate(-45deg);
  transform-origin: bottom right;
  text-align: center;
  z-index: 2 !important;
`;

const CardTop = styled(Box)`
  position: relative;
  height: 155px;
`;

const CardTopText = styled(Typography)`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  color: #fff;
  padding: 8px 16px;
  font-weight: 600;
  background-image: linear-gradient(to bottom, transparent, rgba(0, 0, 0, 0.3));
`;

const CardTopRight = styled(Typography)`
  position: absolute;
  right: 16px;
  top: 12px;
`;

interface UnitCardProps {
  unit: UnitType;
  onDislike: () => void;
  onRemove?: (unitId: number) => void;
}

const UnitCard: React.FC<UnitCardProps> = ({ unit, onDislike, onRemove }) => {
  const navigate = useNavigate();

  const { removeUnit } = useUnits();

  const [deleting, setDeleting] = useState(false);

  const image = getFrontThumbnail(unit);
  const info = [
    unit.Info.Text,
    unit.Info.Rooms,
    unit.Info.M2,
    unit.Economy.Money[0].Value,
  ];

  const isVacant = unit.StatusGroupID >= 10 && unit.StatusGroupID <= 50;

  const handleClickDetail = () => {
    navigate(`/units/${unit.UnitID}`);
  };

  const handleClickRemove = () => {
    setDeleting(true);
    removeUnit(unit.UnitID)
      .then((res) => {
        if (res?.Status === "OK" && onRemove) {
          onRemove(unit.UnitID);
        }
      })
      .finally(() => {
        setDeleting(false);
      });
  };

  return (
    <Card
      sx={{
        position: "relative",
        display: "flex",
        flexDirection: "column",
        height: "100%",
        "& > div": {
          zIndex: 0,
        },
      }}
    >
      {!isVacant && <CardOverlay />}
      {!isVacant && (
        <CardRibbon
          sx={{
            backgroundColor: (theme: Theme) => theme.palette.warning.main,
            color: (theme: Theme) => theme.palette.warning.contrastText,
          }}
        >
          {unit.StatusGroupID === 90 && "Udlejet"}
          {unit.StatusGroupID === 80 && "Solgt"}
        </CardRibbon>
      )}
      <CardTop>
        <CardMedia component="img" height="100%" image={image} alt="" />
        <CardTopText>{unit.Info.AcquisitionDate}</CardTopText>
        {unit.IsNewMatch && (
          <CardTopRight>
            <Chip
              label={"Nyt Match"}
              style={{ borderRadius: 5 }}
              sx={{ px: 1 }}
              color="lightGreen"
              size="small"
            />
          </CardTopRight>
        )}
      </CardTop>

      <CardContent>
        {/* address */}
        <Typography mb={2.5} fontSize={18} fontWeight={400}>
          {unit.Address}
        </Typography>
        {/* info */}
        <Box mb={2}>
          {info.map((val, i) => (
            <Chip
              key={i}
              label={val}
              color="secondary"
              size="small"
              sx={{ mr: 2, mb: 2 }}
            />
          ))}
        </Box>
        {/* headline */}
        <Typography>{unit.Headline}</Typography>
      </CardContent>
      <CardActions
        sx={{
          borderTop: "1px solid #F0EDE7",
          px: 4,
          py: 3,
          mt: "auto",
          justifyContent: "space-between",
          zIndex: "3 !important",
        }}
      >
        <Box>
          {isVacant ? (
            <Button
              size="small"
              color="primary"
              variant="contained"
              onClick={handleClickDetail}
            >
              Se bolig
            </Button>
          ) : (
            <Button
              size="small"
              color="error"
              variant="contained"
              onClick={handleClickRemove}
              disabled={deleting}
            >
              Fjern bolig
            </Button>
          )}
        </Box>
        <Box>
          <Typography>{unit.AutomatchedPercentage}% match</Typography>
        </Box>
        <Box>
          <LikeDislikeButtons unit={unit} />
        </Box>
      </CardActions>
    </Card>
  );
};

export default UnitCard;
