import { CloudUpload } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Slider,
  Typography,
} from "@mui/material";
import React, { useCallback, useRef, useState } from "react";
import Cropper from "react-easy-crop";
import { Area } from "react-easy-crop/types";
import { UserInfoType } from "../types";
import useUser from "../useUser";
import { getCroppedImg } from "../utils";

const UserAvatarForm: React.FC<{
  data: UserInfoType;
}> = ({ data }) => {
  const { updateInfo, avatar, forceAvatarCache } = useUser();
  const [uploading, setUploading] = useState(false);
  const inputFileRef = useRef<HTMLInputElement>(null);

  const [imageSrc, setImageSrc] = useState<string | null>(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState<number>(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area | null>(null);

  const onCropComplete = useCallback((_, croppedAreaPixels: Area) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  /**
   * @deprecated
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const uploadImage = async (file: File) => {
    const base64 = await convertBase64(file);
    try {
      setUploading(true);
      await updateInfo({
        Avatar: String(base64),
      });
      forceAvatarCache();
    } catch (error) {
      // get error
    } finally {
      setUploading(false);
    }
  };

  /**
   * @deprecated
   */
  const convertBase64 = (file: File) => {
    return new Promise<any | ArrayBuffer | null>((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const uploadCroppedImage = async () => {
    if (imageSrc && croppedAreaPixels) {
      const croppedImage = await getCroppedImg(imageSrc, croppedAreaPixels);
      if (croppedImage) {
        try {
          setUploading(true);
          await updateInfo({
            Avatar: croppedImage,
          });
          setImageSrc(null);
          forceAvatarCache();
        } catch (error) {
          // get error
        } finally {
          setUploading(false);
        }
      }
    }
  };

  const handleFileSelect = async (fileList: FileList | null) => {
    console.log(fileList);
    if (!fileList || fileList.length === 0) {
      // no file list
      return;
    }

    const file = fileList[0];
    const fileSize = file.size / 1024 / 1024;
    const fileSizeFixed = fileSize.toFixed(1);

    const maxSize = 1024 * 1024; // 1 MB
    if (file.size <= maxSize) {
      // validated....
      // uploadImage(file);
      // popup crop
      let imageDataUrl = await readFile(file);
      console.log(imageDataUrl);
      setImageSrc(imageDataUrl);
    } else {
      alert(
        "Billedet kan maks fylde 1Mb \nDit billede fylder " +
          fileSizeFixed +
          "Mb"
      );
    }
  };

  const readFile = (file: Blob): Promise<string> => {
    return new Promise<any>((resolve) => {
      const reader = new FileReader();
      reader.addEventListener("load", () => resolve(reader.result), false);
      reader.readAsDataURL(file);
    });
  };

  const handleClose = () => {
    if (inputFileRef?.current) {
      // reset input
      inputFileRef.current.files = null;
      inputFileRef.current.value = "";
    }
    setImageSrc(null);
  };

  const onButtonClick = () => {
    if (inputFileRef?.current) {
      // reset input and open file selector
      inputFileRef.current.files = null;
      inputFileRef.current.value = "";
      inputFileRef.current.click();
    }
  };

  return (
    <>
      <Card>
        <CardContent>
          <Grid container alignItems="center">
            <Grid item xs={12} xl={4}>
              <Avatar
                src={avatar}
                sx={{
                  width: 100,
                  height: 100,
                  mx: "auto",
                  mb: { xs: 5, xl: 0 },
                }}
              />
            </Grid>
            <Grid item xs={12} xl={8}>
              <Box textAlign="center" mb={3}>
                <input
                  type="file"
                  ref={inputFileRef}
                  accept="image/jpeg, image/jpg, image/png"
                  style={{ display: "none" }}
                  onChange={(e) => {
                    console.log("on change");
                    handleFileSelect(e.target.files);
                  }}
                />
                <Button
                  variant="contained"
                  color="primary"
                  component="span"
                  startIcon={<CloudUpload />}
                  disabled={uploading}
                  onClick={onButtonClick}
                >
                  {uploading ? "Uploading" : "Upload billede"}
                </Button>
              </Box>
              <Typography textAlign="center" fontSize={11}>
                For bedste resultat, brug et billede der er mindst 128px i jpg.
                format
              </Typography>
              <Typography textAlign="center" fontSize={11} sx={{ mt: 1 }}>
                Billedet kan maks fylde 1Mb.
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      <Dialog
        fullWidth={true}
        maxWidth="xs"
        open={imageSrc !== null}
        onClose={handleClose}
      >
        <DialogTitle>Update avatar</DialogTitle>
        <DialogContent>
          {imageSrc && (
            <Box>
              <Box
                sx={{
                  position: "relative",
                  height: 200,
                }}
              >
                <Cropper
                  image={imageSrc}
                  crop={crop}
                  zoom={zoom}
                  aspect={1 / 1}
                  cropShape="round"
                  onCropChange={setCrop}
                  onCropComplete={onCropComplete}
                  onZoomChange={setZoom}
                />
              </Box>
              <Box my={4}>
                <Slider
                  value={zoom}
                  min={1}
                  max={3}
                  step={0.1}
                  onChange={(e, zoom) => setZoom(Number(zoom))}
                />
              </Box>
              <Box textAlign="center">
                <Button
                  onClick={handleClose}
                  sx={{ mr: 3 }}
                  disabled={uploading}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  onClick={uploadCroppedImage}
                  disabled={uploading}
                >
                  {uploading ? "Uploading" : "Upload billede"}
                </Button>
              </Box>
            </Box>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};
export default UserAvatarForm;
