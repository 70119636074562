import { Box, CssBaseline, Paper as MuiPaper } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { spacing } from "@mui/system";
import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import styled from "styled-components/macro";
import Navbar from "../components/navbar/Navbar";
import dashboardItems from "../components/sidebar/dashboardItems";
import Sidebar from "../components/sidebar/Sidebar";
import { SidebarItemsType } from "../components/sidebar/types";
import { useProfile } from "../modules/profile";
import GlobalStyle from "../theme/GlobalStyle";

const mobileLinks = [
  {
    title: " ",
    pages: [
      {
        href: "/how-it-works",
        title: "HVORDAN DET VIRKER",
      },
      {
        href: "/about-nextkey",
        title: "OM NEXTKEY",
      },
    ] as SidebarItemsType[],
  },
];

const drawerWidth = 258;

const Root = styled.div`
  display: flex;
  min-height: 100vh;
`;

const Drawer = styled.div`
  ${(props) => props.theme.breakpoints.up("md")} {
    width: ${drawerWidth}px;
    flex-shrink: 0;
  }
`;

const AppContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  max-width: 100%;
`;

const Paper = styled(MuiPaper)(spacing);

const MainContent = styled(Paper)`
  flex: 1;
  background: ${(props) => props.theme.palette.background.default};

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    flex: none;
  }

  .MuiPaper-root .MuiPaper-root {
    box-shadow: none;
  }
`;

const Dashboard: React.FC = ({ children }) => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const { sidebarItems: profileSidebarItems } = useProfile();
  const [sidebarItemsInit, setSidebarItemsInit] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const theme = useTheme();
  const isLgUp = useMediaQuery(theme.breakpoints.up("lg"));

  useEffect(() => {
    // add dynamic profile page items.
    if (dashboardItems && !sidebarItemsInit && profileSidebarItems) {
      let pages = dashboardItems[0].pages.find((item) => {
        return item.href === "/profile";
      });
      // pages already exist.
      if (pages) {
        return;
      }
      dashboardItems[0].pages.splice(1, 0, profileSidebarItems);
      setSidebarItemsInit(true);
    }
  }, [profileSidebarItems, sidebarItemsInit]);

  return (
    <Root>
      <CssBaseline />
      <GlobalStyle />
      <Drawer>
        <Box sx={{ display: { xs: "block", lg: "none" } }}>
          <Sidebar
            PaperProps={{ style: { width: drawerWidth } }}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            items={[...dashboardItems, ...mobileLinks]}
          />
        </Box>
        <Box sx={{ display: { xs: "none", md: "block" } }}>
          <Sidebar
            PaperProps={{ style: { width: drawerWidth } }}
            items={dashboardItems}
          />
        </Box>
      </Drawer>
      <AppContent>
        <Navbar onDrawerToggle={handleDrawerToggle} />
        <MainContent style={{ borderRadius: 0 }} p={isLgUp ? 12 : 5}>
          {children}
          <Outlet />
        </MainContent>
      </AppContent>
    </Root>
  );
};

export default Dashboard;
