import { Box, Grid, Paper, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useProfile } from "../modules/profile/";
import useWelcome from "../modules/profile/components/useWelcome";
import MatchDislikeDialog from "../modules/units/components/MatchDislikeDialog";
import UnitCard from "../modules/units/components/UnitCard";
import UnitCardShimmer from "../modules/units/components/UnitCardShimmer";
import { UnitType } from "../modules/units/type";
import useUnits from "../modules/units/useUnits";
import DashboardPreferences from "./DashboardPreferenceCard";
import DashboardProfileCard from "./DashboardProfileCard";
import MainContainer from "./MainContainer";
const Welcome = (props: any) => {
  const navigate = useNavigate();
  function GotoUnfinishedGroup(group: string) {
    navigate(`/profile/${group}`);
  }
  return (
    <Paper sx={{ p: { lg: 8, xs: 5 } }}>
      <Typography component="h2" variant="prataTitle" mb={2}>
        {props.title}
      </Typography>
      {props.profileFinished ? (
        <Typography variant="body2">
          {/* Du har <Chip label="0" color="darkBlue" size="small" component="span" />{" "}
        nyt bolig matche siden dit sidste besøg. */}
          {props.text}
        </Typography>
      ) : (
        <>
          {props.questionsData.ProfileOpenGroups.length > 0 ? (
            <>
              <Typography
                onClick={() => {
                  GotoUnfinishedGroup(
                    props.questionsData.ProfileOpenGroups[0].RouteName
                  );
                }}
                variant="body2"
                sx={{
                  textDecorationLine: "underline",
                  cursor: "pointer",
                  color: "red",
                }}
              >
                {
                  "BEMÆRK! Da du mangler at færdiggøre din profil, får du ikke nogle matches endnu. Tryk her for at fortsætte."
                }
              </Typography>
            </>
          ) : (
            <></>
          )}
        </>
      )}
    </Paper>
  );
};
function DashboardPage() {
  const [openDislikeDialog, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const onDislike = () => {
    //dislike unit
    setOpen(true);
  };

  const { status, data, finished } = useProfile();
  const { greeting, greetingIsLoading } = useWelcome(true);
  const { units, isLoading } = useUnits(true);

  const [sortedUnits, setSortedUnits] = useState<UnitType[]>([]);

  useEffect(() => {
    setSortedUnits(
      [...units].sort((unit) => {
        return unit.StatusGroupID >= 10 && unit.StatusGroupID <= 50 ? -1 : 1;
      })
    );
  }, [units]);

  return (
    <MainContainer>
      {greetingIsLoading ? (
        <></>
      ) : (
        <>
          {status === "initialized" ? (
            <Welcome
              title={greeting.Title}
              text={greeting.Text}
              profileFinished={finished}
              questionsData={data}
            />
          ) : (
            <></>
          )}
        </>
      )}
      <Grid container sx={{ pt: 5 }} spacing={8}>
        <Grid item xs={12} xl={3} sx={{ mt: 10 }}>
          <DashboardProfileCard />
        </Grid>
        <Grid item xs={12} xl={3} sx={{ mt: 10 }}>
          <DashboardPreferences />
        </Grid>
      </Grid>
      <Box sx={{ mt: 15 }}>
        {openDislikeDialog && (
          <MatchDislikeDialog
            onClose={() => handleClose}
            open={openDislikeDialog}
          />
        )}
        <Typography
          variant="h2"
          component="h2"
          sx={{
            fontFamily: "Helvetica, Poppins, Arial",
            letterSpacing: "0.1em",
            mb: 6,
          }}
        >
          Nyeste boligmatches
        </Typography>

        <Typography mb={8}>Se dine nyeste bolig matches.</Typography>

        <Grid container spacing={8}>
          {isLoading ? (
            <>
              {[0, 1, 2, 3].map((_, i) => (
                <Grid key={i} item xs={12} sm={6} xl={3} lg={4} md={6}>
                  <UnitCardShimmer />
                </Grid>
              ))}
            </>
          ) : (
            <>
              {sortedUnits.map((unit, i) => {
                return (
                  unit.IsNewMatch && (
                    <Grid key={i} item xs={12} sm={6} xl={3} lg={4} md={6}>
                      <UnitCard unit={unit} onDislike={() => onDislike()} />
                    </Grid>
                  )
                );
              })}
            </>
          )}
        </Grid>
      </Box>
    </MainContainer>
  );
}

export default DashboardPage;
