import { Alert, Box } from "@mui/material";
import { useLocation } from "react-router-dom";
import LoginContent from "../components/LoginContent";

function LoginPage() {
  const location: any = useLocation();

  return (
    <>
      {location.state && location.state.invalidToken && (
        <Alert severity="warning">{location.state.invalidToken}</Alert>
      )}
      <Box
        sx={{
          display: "flex",
          minHeight: "100vh",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          py: 10,
          px: 5,
        }}
      >
        <LoginContent />
      </Box>
    </>
  );
}

export default LoginPage;
