import { Star, StarOutline } from "@mui/icons-material";
import { IconButton, IconButtonProps } from "@mui/material";
import React from "react";
// import styled from "styled-components";

// const FavoriteIcon = styled(StarTwoTone)`
//   > path:first-of-type {
//     fill: #ffd600;
//     opacity: 1;
//   }
// `;

type FavoriteIconButtonProps = IconButtonProps & {
  isActive: boolean;
};

const FavoriteIconButton: React.FC<FavoriteIconButtonProps> = ({
  isActive,
  ...props
}) => {
  return (
    <IconButton sx={{ p: 0 }} color="secondary" {...props}>
      {isActive ? (
        <Star
          sx={{
            color: "#ffd600",
          }}
        />
      ) : (
        <StarOutline sx={{ opacity: 0.25 }} />
      )}
    </IconButton>
  );
};

export default FavoriteIconButton;
