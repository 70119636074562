import { Alert, Box, Button, Grid, TextField } from "@mui/material";
import { Formik } from "formik";
import React from "react";
import * as Yup from "yup";
import useLog, { Action } from "../../logs/useLogs";
import { UserInfoType } from "../types";
import useUser from "../useUser";

type InfoProps = {
  label: JSX.Element | string;
};
const UserAccountForm: React.FC<{
  data: UserInfoType;
  handleBack?: () => void;
}> = ({ data, handleBack }) => {
  const { updateInfo } = useUser();
  const { send: sendLog } = useLog();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const Info: React.FC<InfoProps> = ({ label, children }) => {
    return (
      <Box sx={{ fontWeight: 400, mb: 6 }}>
        <Box
          sx={{
            color: "rgba(9, 31, 44, 0.5)",
            fontSize: 11,
            mb: 2,
          }}
        >
          {label}
        </Box>
        {children}
      </Box>
    );
  };
  return (
    <>
      <Formik
        initialValues={{
          password: "",
          passwordVerify: "",
          submit: false,
        }}
        validationSchema={Yup.object().shape({
          passwordVerify: Yup.string().oneOf(
            [Yup.ref("password"), null],
            "Begge kodeord skal matche"
          ),
        })}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          try {
            setStatus({ success: false });

            // handle submitted data.
            await updateInfo({
              Password: values.password,
            });
            setStatus({ success: true });
            sendLog(Action.CHANGED_PASSWORD, {}, undefined);
          } catch (error: any) {
            const message = error.message || "Something went wrong";

            setStatus({ success: false });
            setErrors({ submit: message });
          } finally {
            setSubmitting(false);
          }
        }}
      >
        {({
          errors,
          status,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
        }) => (
          <form noValidate onSubmit={handleSubmit}>
            {errors.submit && (
              <Alert sx={{ mb: 5 }} severity="warning">
                {errors.submit}
              </Alert>
            )}
            {status?.success && (
              <Alert sx={{ mb: 5 }} severity="success">
                Dine ændringer er opdateret
              </Alert>
            )}
            <Grid container columnSpacing={{ lg: 8, xs: 5 }}>
              <Grid item md={6} xs={12}>
                {/* password */}
                <TextField
                  type="password"
                  name="password"
                  placeholder="Nyt kodeord"
                  label="Nyt kodeord"
                  variant="standard"
                  value={values.password}
                  fullWidth
                  onChange={handleChange}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                {/* verify password */}
                <TextField
                  type="password"
                  name="passwordVerify"
                  placeholder="Bekræft kodeord"
                  label="Bekræft kodeord"
                  variant="standard"
                  error={Boolean(
                    touched.passwordVerify && errors.passwordVerify
                  )}
                  fullWidth
                  helperText={touched.passwordVerify && errors.passwordVerify}
                  value={values.passwordVerify}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
            <Box mt={8}>
              <Button
                type="submit"
                variant="contained"
                disabled={
                  values.password === ""
                    ? true
                    : values.password === values.passwordVerify
                    ? false
                    : true
                }
                sx={{
                  mr: 5,
                }}
              >
                GEM
              </Button>
              {handleBack && (
                <Button
                  type="button"
                  variant="text"
                  disabled={isSubmitting}
                  onClick={handleBack}
                >
                  FORTRYD
                </Button>
              )}
            </Box>
          </form>
        )}
      </Formik>
    </>
  );
};

export default UserAccountForm;
