import { Alert, Box, Button, Grid, TextField } from "@mui/material";
import { Formik } from "formik";
import React from "react";
import * as Yup from "yup";
import { UserInfoType } from "../types";
import useUser from "../useUser";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";

const UserInfoForm: React.FC<{
  data: UserInfoType;
  handleBack?: () => void;
}> = ({ data, handleBack }) => {
  const { updateInfo } = useUser();

  return (
    <>
      <Formik
        initialValues={{
          email: data.Email,
          firstName: data.FirstName,
          lastName: data.LastName,
          phone: data.Telephone,
          street: data.Street,
          houseno: data.Houseno,
          zipCode: data.ZipCode,
          city: data.City,
          notifications: data.Notifications,
          submit: false,
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string()
            .email("Must be a valid email")
            .max(255)
            .required("Email is required"),
        })}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          try {
            setStatus({ success: false });

            // handle submitted data.
            await updateInfo({
              Email: values.email,
              FirstName: values.firstName,
              LastName: values.lastName,
              Street: values.street,
              Houseno: values.houseno,
              ZipCode: values.zipCode,
              City: values.city,
              Telephone: values.phone,
              Notifications: values.notifications,
            });
            setStatus({ success: true });
          } catch (error: any) {
            const message = error.message || "Something went wrong";

            setStatus({ success: false });
            setErrors({ submit: message });
          } finally {
            setSubmitting(false);
          }
        }}
      >
        {({
          errors,
          status,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
        }) => (
          <form noValidate onSubmit={handleSubmit}>
            {errors.submit && (
              <Alert sx={{ mb: 5 }} severity="warning">
                {errors.submit}
              </Alert>
            )}
            {status?.success && (
              <Alert sx={{ mb: 5 }} severity="success">
                Dine ændringer er opdateret
              </Alert>
            )}
            <Grid container columnSpacing={{ lg: 8, xs: 5 }} rowSpacing={5}>
              <Grid item md={6} xs={12}>
                {/* firstName */}
                <TextField
                  type="text"
                  name="firstName"
                  placeholder="Fornavn"
                  label="Fornavn"
                  variant="standard"
                  value={values.firstName}
                  fullWidth
                  onChange={handleChange}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                {/* lastName */}
                <TextField
                  type="text"
                  name="lastName"
                  label="Efternavn"
                  variant="standard"
                  value={values.lastName}
                  fullWidth
                  onChange={handleChange}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                {/* email */}
                <TextField
                  type="email"
                  name="email"
                  label="E-mail"
                  variant="standard"
                  value={values.email}
                  error={Boolean(touched.email && errors.email)}
                  fullWidth
                  helperText={touched.email && errors.email}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                {/* phone */}
                <TextField
                  type="text"
                  name="phone"
                  label="Telefonnummer"
                  variant="standard"
                  value={values.phone}
                  fullWidth
                  onChange={handleChange}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                {/* street */}
                <TextField
                  type="text"
                  name="street"
                  label="Vejnavn"
                  variant="standard"
                  value={values.street}
                  fullWidth
                  onChange={handleChange}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                {/* house no */}
                <TextField
                  type="text"
                  name="houseno"
                  label="Husnr"
                  variant="standard"
                  value={values.houseno}
                  fullWidth
                  onChange={handleChange}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                {/* zipCode */}
                <TextField
                  type="text"
                  name="zipCode"
                  label="Postnummer"
                  variant="standard"
                  value={values.zipCode}
                  fullWidth
                  onChange={handleChange}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                {/* city */}
                <TextField
                  type="text"
                  name="city"
                  label="By"
                  variant="standard"
                  value={values.city}
                  fullWidth
                  onChange={handleChange}
                />
              </Grid>

              <Grid item md={6} xs={12}>
                {/* Notifications */}
                <FormControlLabel
                  sx={{
                    m: 0,
                    alignItems: "flex-start",
                  }}
                  control={
                    <Switch
                      defaultChecked={values.notifications}
                      name="notifications"
                      onChange={handleChange}
                    />
                  }
                  label="Send email når der er nye boliger"
                  labelPlacement="top"
                />
              </Grid>
            </Grid>
            <Box mt={8}>
              <Button
                type="submit"
                variant="contained"
                disabled={isSubmitting}
                sx={{
                  mr: 5,
                }}
              >
                GEM
              </Button>
              {handleBack && (
                <Button
                  type="button"
                  variant="text"
                  disabled={isSubmitting}
                  onClick={handleBack}
                >
                  FORTRYD
                </Button>
              )}
            </Box>
          </form>
        )}
      </Formik>
    </>
  );
};

export default UserInfoForm;
