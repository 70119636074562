import { Typography } from "@mui/material";
import React from "react";

const SectionTitle: React.FC = ({ children }) => {
  return (
    <Typography color="primary" component="h3" variant="h5" mb={3}>
      {children}
    </Typography>
  );
};

export default SectionTitle;
