import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../redux/store";

type AuthStateType = {
  openLoginModal: boolean;
  openSignupModal: boolean;
};

const initialState: AuthStateType = {
  openLoginModal: false,
  openSignupModal: false,
};

export const slice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    openLoginModal: (state) => {
      state.openLoginModal = true;
    },
    closeLoginModal: (state) => {
      state.openLoginModal = false;
    },
    openSignupModal: (state) => {
      state.openSignupModal = true;
    },
    closeSignupModal: (state) => {
      state.openSignupModal = false;
    },
  },
});

export const {
  openLoginModal,
  closeLoginModal,
  openSignupModal,
  closeSignupModal,
} = slice.actions;

export const selectOpenLoginModal = (state: RootState) =>
  state.auth.openLoginModal;
export const selectOpenSignupModal = (state: RootState) =>
  state.auth.openSignupModal;

export default slice.reducer;
