import { Box } from "@mui/material";
import React from "react";

const MainContainer: React.FC = ({ children }) => {
  return (
    <Box maxWidth={1510} mx="auto">
      {children}
    </Box>
  );
};

export default MainContainer;
