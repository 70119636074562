import { Box, Chip, Typography } from "@mui/material";
import _ from "lodash";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { AnswerType, OptionType, QuestionType } from "../types";
import useProfile from "../useProfile";
import FavoriteIconButton from "./FavoriteIconButton";

export const OptionChip = styled(Chip)`
  height: 28px;
  font-size: 12px;
  font-weight: 400;
  border-radius: 4px;
  margin-right: 8px;
  margin-bottom: 8px;
  transition: all 0.01s;

  .MuiChip-label {
    padding-left: 15px;
    padding-right: 15px;
  }
`;

type FieldChipsProps = {
  question: QuestionType;
};

const FieldChips: React.FC<FieldChipsProps> = ({ question }) => {
  const [isFavorite, setIsFavorite] = useState<boolean>(question.Favorite);
  const [selected, setSelected] = useState<Array<OptionType>>([]);
  const [init, setInit] = useState(false);
  const [minWidth, setMinWidth] = useState(0);
  const { update } = useProfile();

  const updateRef = useRef(
    _.debounce(async (data: AnswerType) => {
      // TODO: compare with previous data.
      await update([data]);
    }, 2000)
  );

  useEffect(() => {
    // set initial selected.
    if (question.Options) {
      setSelected(
        question.Options.reduce<Array<OptionType>>((filtered, option) => {
          if (option.Selected) {
            filtered.push(option);
          }
          return filtered;
        }, [])
      );
    }
    setIsFavorite(question.Favorite);
  }, [question]);

  const handleClick = (value: OptionType) => {
    if (selected.includes(value)) {
      setSelected(selected.filter((v) => v !== value));
    } else {
      setSelected([...selected, value]);
    }
    setInit(true);
  };

  const handleFavorite = () => {
    setInit(true);
    setIsFavorite(!isFavorite);
  };

  useEffect(() => {
    if (init) {
      updateRef.current.cancel();
      const data: AnswerType = {
        ID: Number(question.ID),
        Favorite: isFavorite ? 1 : 0,
        AnsweredValues: selected.map((item) => {
          return {
            ID: item.Value,
            Value: true,
          };
        }),
      };
      updateRef.current(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFavorite, init, selected]);

  return (
    <>
      {/* title and favorite icon */}
      <Box mb={3} sx={{ display: "flex", alignItems: "center" }}>
        <Box mr={1} lineHeight={1}>
          {/* favorite icon button */}
          <FavoriteIconButton isActive={isFavorite} onClick={handleFavorite} />
        </Box>

        <Typography fontWeight={500} display="inline-block">
          {question.Prefix}
        </Typography>
        {question.Mandatory ? (
          <Typography sx={{ color: "red", fontWeight: "bold", ml: 2 }}>
            *
          </Typography>
        ) : (
          <></>
        )}
      </Box>

      {/* chips */}
      {question.Options && (
        <Box
          sx={{
            display: "flex",
            flexDirection: question.Options.length > 10 ? "row" : "column",
            flexWrap: "wrap",
            alignItems: "stretch",
            pl: 7,
          }}
        >
          {question.Options.map((option, i) => {
            return (
              <OptionChip
                key={i}
                ref={(ref) => {
                  if (ref) {
                    if (
                      ref.offsetWidth > minWidth &&
                      question.Options.length > 10
                    ) {
                      // equal width if only row layout
                      setMinWidth(ref.offsetWidth);
                    }
                  }
                }}
                label={option.ShowValue}
                onClick={() => {
                  handleClick(option);
                }}
                sx={{
                  minWidth: question.Options.length > 10 ? minWidth : 0, // equal width if only row layout
                }}
                variant={selected.includes(option) ? "filled" : "outlined"}
                color={selected.includes(option) ? "secondary" : "default"}
              />
            );
          })}
        </Box>
      )}
    </>
  );
};

export default FieldChips;
