import mock from "./adapter";

function fakeRequest(time: number) {
  return new Promise((res) => setTimeout(res, time));
}

const units = {
  Units: [
    {
      UnitID: 371,
      Project: "Himmerland Boligforening",
      Headline: "Udsigt, beliggenhed og shopping",
      Address: "Sivholmen 35. 2. th, 2450 København SV",
      ZipCode: 9000,
      City: "Aalborg",
      Title: "",
      Description:
        "Top moderne og l&#230;kre lejligheder med erhvervslokaler tilbygget. \r\nLejlighederne p&#229; Fyrkildevej er bygget i kvalitetsmaterialer og deres indretning er praktisk, s&#229; det passer til en travl hverdag. Alle h&#229;rde hvidevare er installeret og der medf&#248;lger gratis p-pladser til ejendommen.",
      Geocode: "",
      GoogleMaps:
        "https://www.google.com/maps/embed/v1/place?key=AIzaSyA17Fz29xAbyvsuFxOENqNDRFlyVLd9j8Q&center=&zoom=18&maptype=satellite&q=",
      Info: {
        Text: "Lejlighed",
        Rooms: "Ikke angivet",
        M2: "100 m²",
        Facility: "",
        Pets: "Tilladt op til 7 kg.",
        AcquisitionDate: "19-11-2021",
        Money: "14.900 kr./mdr.",
      },
      Economy: {
        Money: [
          {
            Title: "Leje pr. md.",
            Value: "14.900 kr.",
          },
          {
            Title: "A conto varme",
            Value: "914 kr.",
          },
          {
            Title: "A conto vand",
            Value: "484 kr.",
          },
        ],
        Other: [
          {
            Title: "Depositum",
            Value: "59.985 kr.",
          },
          {
            Title: "Forudbetalt leje",
            Value: "19.995 kr.",
          },
        ],
      },
      Terms: {
        Conditions: [
          {
            Title: "Leje betaling",
            Value: "Månedsvis forud",
          },
          {
            Title: "Uopsigelig",
            Value: "6 måneder",
          },
        ],
        Other: [
          {
            Title: "Husdyrstilladelse",
            Value: "Ansøges hos udlejer",
          },
        ],
      },
      Images: [
        {
          Url: "https://demo.estatetool.net/media/bolig/ac29bde2b996fa422a55332933fc8f96_C.jpg",
          Thumbnail:
            "https://demo.estatetool.net/media/bolig/ac29bde2b996fa422a55332933fc8f96_A.jpg",
          Tags: ["Altan", "Terasse", "Værelse"],
          IsFront: true,
          IsFloorplan: false,
        },
        {
          Url: "https://demo.estatetool.net/media/bolig/8b26830ad3502603d5164d107ff1fe30_C.jpg",
          Thumbnail:
            "https://demo.estatetool.net/media/bolig/8b26830ad3502603d5164d107ff1fe30_A.jpg",
          Tags: ["Bad", "Belysning"],
          IsFront: false,
          IsFloorplan: false,
        },
        {
          Url: "https://demo.estatetool.net/media/bolig/e3e357ce737b28347dc128cc0fb05d7f_C.jpg",
          Thumbnail:
            "https://demo.estatetool.net/media/bolig/e3e357ce737b28347dc128cc0fb05d7f_A.jpg",
          Tags: ["Plantegning"],
          IsFront: false,
          IsFloorplan: true,
        },
      ],
      Price: "1.234.000",
      IsNewMatch: false,
      MatchRequirementsMet: "Opfylder 4 ud af 5 favoritter til drømmeboligen",
      UserLikes: true,
      UserDisLikes: false,
    },
    {
      UnitID: 388,
      Project: "Hasserisparken",
      Headline: "Udsigt, beliggenhed og shopping",
      Address: "Sivholmen 35. 2. th, 2450 København SV",
      ZipCode: 9000,
      City: "Aalborg",
      Title: "",
      Description: "",
      Geocode: "",
      GoogleMaps:
        "https://www.google.com/maps/embed/v1/place?key=AIzaSyA17Fz29xAbyvsuFxOENqNDRFlyVLd9j8Q&center=&zoom=18&maptype=satellite&q=",
      Info: {
        Text: "Lejlighed",
        Rooms: "Ikke angivet",
        M2: "99 m²",
        Facility: "",
        Pets: "Tilladt op til 7 kg.",
        AcquisitionDate: "19-11-2021",
        Money: "14.900 kr./mdr.",
      },
      Economy: {
        Money: [
          {
            Title: "Leje pr. md.",
            Value: "14.900 kr.",
          },
          {
            Title: "A conto varme",
            Value: "914 kr.",
          },
          {
            Title: "A conto vand",
            Value: "484 kr.",
          },
        ],
        Other: [
          {
            Title: "Depositum",
            Value: "59.985 kr.",
          },
          {
            Title: "Forudbetalt leje",
            Value: "19.995 kr.",
          },
        ],
      },
      Terms: {
        Conditions: [
          {
            Title: "Leje betaling",
            Value: "Månedsvis forud",
          },
          {
            Title: "Uopsigelig",
            Value: "6 måneder",
          },
        ],
        Other: [
          {
            Title: "Husdyrstilladelse",
            Value: "Ansøges hos udlejer",
          },
        ],
      },
      Images: [
        {
          Url: "https://demo.estatetool.net/media/bolig/ac29bde2b996fa422a55332933fc8f96_C.jpg",
          Thumbnail:
            "https://demo.estatetool.net/media/bolig/ac29bde2b996fa422a55332933fc8f96_A.jpg",
          Tags: ["Altan", "Terasse", "Værelse"],
          IsFront: true,
          IsFloorplan: false,
        },
        {
          Url: "https://demo.estatetool.net/media/bolig/8b26830ad3502603d5164d107ff1fe30_C.jpg",
          Thumbnail:
            "https://demo.estatetool.net/media/bolig/8b26830ad3502603d5164d107ff1fe30_A.jpg",
          Tags: ["Bad", "Belysning"],
          IsFront: false,
          IsFloorplan: false,
        },
        {
          Url: "https://demo.estatetool.net/media/bolig/e3e357ce737b28347dc128cc0fb05d7f_C.jpg",
          Thumbnail:
            "https://demo.estatetool.net/media/bolig/e3e357ce737b28347dc128cc0fb05d7f_A.jpg",
          Tags: ["Plantegning"],
          IsFront: false,
          IsFloorplan: true,
        },
      ],
      Price: "1.234.000",
      IsNewMatch: true,
      MatchRequirementsMet: "Opfylder 4 ud af 5 favoritter til drømmeboligen",
      UserLikes: true,
      UserDisLikes: false,
    },
    {
      UnitID: 266,
      Project: "Downtown Apartments",
      Headline: "Udsigt, beliggenhed og shopping",
      Address: "Sivholmen 35. 2. th, 2450 København SV",
      ZipCode: 9800,
      City: "Hjørring",
      Title: "",
      Description:
        "Denne lejlighed er en af flere i et nybyggeri fra 2020, som er tilt&#230;nkt seniorgenerationen og tilbyder diverse ekstra service i en lejlighed der kan byde p&#229; lidt mere end normalt.\r\nHver lejlighed er udstyret med en iPad, hvorfra man kan tilk&#248;be sig en lang r&#230;kke services som: \r\n- Reng&#248;ringshj&#230;lp\r\n- Hj&#230;lp til at handle\r\n- Fysioterapi\r\n- Hjemmefris&#248;r\r\n... og meget mere\r\n\r\nUdover disse services er der i ejendommen fokus p&#229; f&#230;llesskab. I bygningen er en f&#230;llestagterrasse med mulighed for petanque og et glas vin med en ven eller nabo. Det er derfor naturligvis ogs&#229; tilladt at have sine firbenede venner boende i lejlighederne.\r\nDer er oprettet en beboerforening, som j&#230;vnligt st&#229;r for f&#230;lles arrangementer.\r\n\r\nEjendommen er beliggende i Hj&#248;rring centrum og kan ikke v&#230;re t&#230;ttere p&#229; forskellige indk&#248;bsmuligheder. Du/I har b&#229;de mulighed for at handle ind i F&#248;tex, Harald Nyborg, Elgiganten og en butik med alt til k&#230;ledyr. \r\n\r\nSom seniorbolig f&#229;r du det ikke meget bedre. Lejlighederne er udstyret med alt indenfor h&#229;rde hvidevarer, og der er tilh&#248;rende altan eller terrasse til hver lejlighed. Den ekstra service g&#248;r blot, at du ikke kommer til at mangle noget, som du ikke var klar over, du havde brug for.",
      Geocode: "",
      GoogleMaps:
        "https://www.google.com/maps/embed/v1/place?key=AIzaSyA17Fz29xAbyvsuFxOENqNDRFlyVLd9j8Q&center=&zoom=18&maptype=satellite&q=",
      Info: {
        Text: "Lejlighed",
        Rooms: "Ikke angivet",
        M2: "97 m²",
        Facility: "",
        Pets: "Tilladt op til 7 kg.",
        AcquisitionDate: "19-11-2021",
        Money: "14.900 kr./mdr.",
      },
      Economy: {
        Money: [
          {
            Title: "Leje pr. md.",
            Value: "14.900 kr.",
          },
          {
            Title: "A conto varme",
            Value: "914 kr.",
          },
          {
            Title: "A conto vand",
            Value: "484 kr.",
          },
        ],
        Other: [
          {
            Title: "Depositum",
            Value: "59.985 kr.",
          },
          {
            Title: "Forudbetalt leje",
            Value: "19.995 kr.",
          },
        ],
      },
      Terms: {
        Conditions: [
          {
            Title: "Leje betaling",
            Value: "Månedsvis forud",
          },
          {
            Title: "Uopsigelig",
            Value: "6 måneder",
          },
        ],
        Other: [
          {
            Title: "Husdyrstilladelse",
            Value: "Ansøges hos udlejer",
          },
        ],
      },
      Images: [
        {
          Url: "https://demo.estatetool.net/media/bolig/ac29bde2b996fa422a55332933fc8f96_C.jpg",
          Thumbnail:
            "https://demo.estatetool.net/media/bolig/ac29bde2b996fa422a55332933fc8f96_A.jpg",
          Tags: ["Altan", "Terasse", "Værelse"],
          IsFront: true,
          IsFloorplan: false,
        },
        {
          Url: "https://demo.estatetool.net/media/bolig/8b26830ad3502603d5164d107ff1fe30_C.jpg",
          Thumbnail:
            "https://demo.estatetool.net/media/bolig/8b26830ad3502603d5164d107ff1fe30_A.jpg",
          Tags: ["Bad", "Belysning"],
          IsFront: false,
          IsFloorplan: false,
        },
        {
          Url: "https://demo.estatetool.net/media/bolig/e3e357ce737b28347dc128cc0fb05d7f_C.jpg",
          Thumbnail:
            "https://demo.estatetool.net/media/bolig/e3e357ce737b28347dc128cc0fb05d7f_A.jpg",
          Tags: ["Plantegning"],
          IsFront: false,
          IsFloorplan: true,
        },
      ],
      Price: "1.234.000",
      IsNewMatch: false,
      MatchRequirementsMet: "Opfylder 4 ud af 5 favoritter til drømmeboligen",
      UserLikes: true,
      UserDisLikes: false,
    },
    {
      UnitID: 420,
      Project: "Fortuna IS - Jan Hammershøy",
      Headline: "Udsigt, beliggenhed og shopping",
      Address: "Sivholmen 35. 2. th, 2450 København SV",
      ZipCode: 9000,
      City: "Aalborg",
      Title: "",
      Description:
        "Lejligheden best&#229;r af et sovev&#230;relse, en stue, et k&#248;kken og et badev&#230;relse. Desuden er lejligheden beliggende i det dejlige &#248;gadekvarter, hvor du har adgang til diverse supermarkeder, fitness, butikker og &#216;stre Anl&#230;g. \r\nDer er et depotrum til lejem&#229;let og der er cykelparkering til ejendommen. \r\n\r\nLejem&#229;let har et &#229;rs uopsigelighed.",
      Geocode: "",
      GoogleMaps:
        "https://www.google.com/maps/embed/v1/place?key=AIzaSyA17Fz29xAbyvsuFxOENqNDRFlyVLd9j8Q&center=&zoom=18&maptype=satellite&q=",
      Info: {
        Text: "Lejlighed",
        Rooms: "Ikke angivet",
        M2: "61 m²",
        Facility: "",
        Pets: "Tilladt op til 7 kg.",
        AcquisitionDate: "19-11-2021",
        Money: "14.900 kr./mdr.",
      },
      Economy: {
        Money: [
          {
            Title: "Leje pr. md.",
            Value: "14.900 kr.",
          },
          {
            Title: "A conto varme",
            Value: "914 kr.",
          },
          {
            Title: "A conto vand",
            Value: "484 kr.",
          },
        ],
        Other: [
          {
            Title: "Depositum",
            Value: "59.985 kr.",
          },
          {
            Title: "Forudbetalt leje",
            Value: "19.995 kr.",
          },
        ],
      },
      Terms: {
        Conditions: [
          {
            Title: "Leje betaling",
            Value: "Månedsvis forud",
          },
          {
            Title: "Uopsigelig",
            Value: "6 måneder",
          },
        ],
        Other: [
          {
            Title: "Husdyrstilladelse",
            Value: "Ansøges hos udlejer",
          },
        ],
      },
      Images: [
        {
          Url: "https://demo.estatetool.net/media/bolig/ac29bde2b996fa422a55332933fc8f96_C.jpg",
          Thumbnail:
            "https://demo.estatetool.net/media/bolig/ac29bde2b996fa422a55332933fc8f96_A.jpg",
          Tags: ["Altan", "Terasse", "Værelse"],
          IsFront: true,
          IsFloorplan: false,
        },
        {
          Url: "https://demo.estatetool.net/media/bolig/8b26830ad3502603d5164d107ff1fe30_C.jpg",
          Thumbnail:
            "https://demo.estatetool.net/media/bolig/8b26830ad3502603d5164d107ff1fe30_A.jpg",
          Tags: ["Bad", "Belysning"],
          IsFront: false,
          IsFloorplan: false,
        },
        {
          Url: "https://demo.estatetool.net/media/bolig/e3e357ce737b28347dc128cc0fb05d7f_C.jpg",
          Thumbnail:
            "https://demo.estatetool.net/media/bolig/e3e357ce737b28347dc128cc0fb05d7f_A.jpg",
          Tags: ["Plantegning"],
          IsFront: false,
          IsFloorplan: true,
        },
      ],
      Price: "1.234.000",
      IsNewMatch: false,
      MatchRequirementsMet: "Opfylder 4 ud af 5 favoritter til drømmeboligen",
      UserLikes: true,
      UserDisLikes: false,
    },
    {
      UnitID: 340,
      Project: "Nordjylland",
      Headline: "Udsigt, beliggenhed og shopping",
      Address: "2450 København SV2450 København SV",
      ZipCode: 2000,
      City: null,
      Title: "",
      Description:
        "&lt;p style=&quot;margin-bottom: 10px; line-height: 26px; color: rgb(36, 36, 41); font-family: Montserrat, sans-serif; letter-spacing: normal;&quot;&gt;Lejligheden er opf&#248;rt i kvalitetsmaterialer fra top til bund, og du kan her forvente en tidl&#248;s bolig, der kan danne ramme om dit fremtidige hjem &#8211; og som du kan pr&#230;ge p&#229; din helt egen, personlige m&#229;de. Der medf&#248;lger depotrum.&lt;/p&gt;&lt;p style=&quot;margin-bottom: 10px; line-height: 26px; color: rgb(36, 36, 41); font-family: Montserrat, sans-serif; letter-spacing: normal;&quot;&gt;Lejligheden indeholder alt i h&#229;rde hvidevarer og har udgang til altan.&lt;/p&gt;&lt;p style=&quot;margin-bottom: 10px; line-height: 26px; color: rgb(36, 36, 41); font-family: Montserrat, sans-serif; letter-spacing: normal;&quot;&gt;Denne 4-v&#230;relses lejligheder super unik og fordeler sig p&#229; hele 135 kvadratmeter! Du f&#229;r her en yderst rummelig bolig i top kvalitet i toppen af Skipperhus.&lt;/p&gt;",
      Geocode: "",
      GoogleMaps:
        "https://www.google.com/maps/embed/v1/place?key=AIzaSyA17Fz29xAbyvsuFxOENqNDRFlyVLd9j8Q&center=&zoom=18&maptype=satellite&q=",
      Info: {
        Text: "Lejlighed",
        Rooms: "Ikke angivet",
        M2: "135 m²",
        Facility: "",
        Pets: "Tilladt op til 7 kg.",
        AcquisitionDate: "19-11-2021",
        Money: "14.900 kr./mdr.",
      },
      Economy: {
        Money: [
          {
            Title: "Leje pr. md.",
            Value: "14.900 kr.",
          },
          {
            Title: "A conto varme",
            Value: "914 kr.",
          },
          {
            Title: "A conto vand",
            Value: "484 kr.",
          },
        ],
        Other: [
          {
            Title: "Depositum",
            Value: "59.985 kr.",
          },
          {
            Title: "Forudbetalt leje",
            Value: "19.995 kr.",
          },
        ],
      },
      Terms: {
        Conditions: [
          {
            Title: "Leje betaling",
            Value: "Månedsvis forud",
          },
          {
            Title: "Uopsigelig",
            Value: "6 måneder",
          },
        ],
        Other: [
          {
            Title: "Husdyrstilladelse",
            Value: "Ansøges hos udlejer",
          },
        ],
      },
      Images: [
        {
          Url: "https://demo.estatetool.net/media/bolig/ac29bde2b996fa422a55332933fc8f96_C.jpg",
          Thumbnail:
            "https://demo.estatetool.net/media/bolig/ac29bde2b996fa422a55332933fc8f96_A.jpg",
          Tags: ["Altan", "Terasse", "Værelse"],
          IsFront: true,
          IsFloorplan: false,
        },
        {
          Url: "https://demo.estatetool.net/media/bolig/8b26830ad3502603d5164d107ff1fe30_C.jpg",
          Thumbnail:
            "https://demo.estatetool.net/media/bolig/8b26830ad3502603d5164d107ff1fe30_A.jpg",
          Tags: ["Bad", "Belysning"],
          IsFront: false,
          IsFloorplan: false,
        },
        {
          Url: "https://demo.estatetool.net/media/bolig/e3e357ce737b28347dc128cc0fb05d7f_C.jpg",
          Thumbnail:
            "https://demo.estatetool.net/media/bolig/e3e357ce737b28347dc128cc0fb05d7f_A.jpg",
          Tags: ["Plantegning"],
          IsFront: false,
          IsFloorplan: true,
        },
      ],
      Price: "1.234.000",
      IsNewMatch: false,
      MatchRequirementsMet: "Opfylder 4 ud af 5 favoritter til drømmeboligen",
      UserLikes: true,
      UserDisLikes: false,
    },
  ],
};

mock.onGet("/user/matchedunits/").reply(async (config) => {
  try {
    await fakeRequest(2000);

    return [200, units];
  } catch (error) {
    console.error(error);
    return [500, { message: "Internal server error" }];
  }
});
