import { Card, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import { CardContent, CardTitle } from "../components/Card";
import { QuestionType } from "../types";
import FieldChips from "./FieldChips";
import FieldChipsSelect from "./FieldChipsSelect";
import FieldSingleChip from "./FieldSingleChip";
import FieldSlider from "./FieldSlider";
import FieldText from "./FieldText";

type QuestionGroupCardProps = {
  questions: QuestionType[];
  cardTitle?: string;
};

// show question field by type
const QuestionGroupCard: React.FC<QuestionGroupCardProps> = ({
  questions,
  cardTitle,
}) => {
  const [singleChipWidth, setSingleChipWidth] = useState(0);

  return (
    <Card sx={{ mb: 6 }}>
      <CardContent>
        {/* title */}
        {cardTitle && (
          <Typography fontWeight={500} variant="h4" mb={5}>
            {cardTitle}
          </Typography>
        )}
        <Grid container columnSpacing={{ xl: 10, xs: 10 }} rowSpacing={5}>
          {questions.map((question, i) => {
            return (() => {
              switch (question.Type) {
                case "Line":
                  return (
                    <>
                      {question.Prefix && (
                        <Grid item key={i} xs={12}>
                          <CardTitle mb={0}>{question.Prefix}</CardTitle>
                        </Grid>
                      )}
                    </>
                  );
                case "MultiSelect":
                  return (
                    <Grid item key={i}>
                      {question.Options.length === 1 ? (
                        <FieldSingleChip
                          question={question}
                          chipWidth={singleChipWidth}
                          handleChipWidth={(width: number) => {
                            setSingleChipWidth(width);
                          }}
                        />
                      ) : (
                        <FieldChips question={question} />
                      )}
                    </Grid>
                  );
                case "DynamicMultiSelect":
                  return (
                    <Grid xs item key={i}>
                      <FieldChipsSelect question={question} />
                    </Grid>
                  );
                case "Range":
                  return (
                    <Grid xl={3} lg={4} md={6} xs={12} item key={i}>
                      <FieldSlider question={question} />
                    </Grid>
                  );
                case "TextInput":
                  return (
                    <Grid xs={12} lg={8} xl={6} item key={i}>
                      <FieldText question={question} />
                    </Grid>
                  );
                default:
                  return <></>;
              }
            })();
          })}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default QuestionGroupCard;
