import { Box, Typography } from "@mui/material";
import React from "react";
import { UnitType } from "../type";
import CollapseBox from "./BoxCollapse";

const UnitTermsBox: React.FC<{ unit: UnitType }> = ({ unit }) => {
  return (
    <>
      <CollapseBox title="Lejevilkår">
        {unit.Terms &&
          unit.Terms.Conditions.map((item, i) => (
            <Box
              key={i}
              sx={{
                display: "flex",
                my: 4,
                justifyContent: "space-between",
              }}
            >
              <Typography>{item.Title}</Typography>
              <Typography>{item.Value}</Typography>
            </Box>
          ))}
        <hr />
        {unit.Terms &&
          unit.Terms.Other.map((item, i) => (
            <Box
              key={i}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                my: 4,
              }}
            >
              <Typography>{item.Title}</Typography>
              <Typography>{item.Value}</Typography>
            </Box>
          ))}
      </CollapseBox>
    </>
  );
};

export default UnitTermsBox;
