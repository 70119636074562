import { Cancel, Email, Phone, Star } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Button,
  Divider,
  IconButton,
  Link,
  Modal,
  Slide,
  Typography,
} from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import React from "react";
import * as pic from "../../../assets/UserImages/UserImageCollection";

const style = {
  position: "absolute" as "absolute",
  top: "0",
  right: "0",
  width: 670,
  maxWidth: "100%",
  maxHeight: "100%",
  overflow: "auto",
  bgcolor: "background.paper",
  boxShadow: 1,
  borderRadius: "0 0 0 6px",
  p: { xl: 10, xs: 8 },
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const HelpPopup = () => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button
        variant="contained"
        onClick={handleClickOpen}
        sx={{
          width: 180,
          position: "fixed",
          bottom: "30px",
          right: 0,
          textAlign: "left",
          padding: 1.5,
          borderRadius: "40px 0 0 40px",
          fontWeight: 600,
          transform: open ? "translateX(100%)" : "translateX(0)",
          transition: "all 0.3s",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flexShrink: 0,
            justifyContent: "center",
            backgroundColor: "#fff",
            width: 60,
            height: 60,
            borderRadius: "50%",
            marginRight: 2,
          }}
        >
          <Star
            sx={{
              fontSize: "30px",
              color: "#ffd600",
            }}
          />
        </Box>
        <Box>Hvad betyder stjernen?</Box>
      </Button>

      <Modal open={open} onClose={handleClose} closeAfterTransition>
        <Transition in={open}>
          <Box sx={style}>
            {/* close button */}
            <IconButton
              onClick={handleClose}
              sx={{
                position: "absolute",
                right: 10,
                top: 10,
              }}
            >
              <Cancel color="secondary" />
            </IconButton>

            <Box sx={{ display: "flex", alignItems: "flex-start" }}>
              <Star
                sx={{
                  color: "#ffd600",
                  mr: 2,
                }}
              />
              <Typography variant="h4" component="h4" fontWeight={600}>
                Hvad betyder stjernen?
              </Typography>
            </Box>
            <Box sx={{ pl: { xl: 8 } }}>
              <Typography sx={{ mt: 2 }}>
                <p>
                  For at vi kan gøre vores bedste for at finde den rigtige bolig
                  til dig, har du mulighed for at tilrette dine boligønsker -
                  som vi kalder for dine præferencer. Præferencer er de
                  oplysninger vi bruger for at finde ud af, hvad der er ekstra
                  vigtigt for dig.
                </p>
                <p>
                  Hvis du f.eks. ønsker, at den bolig du søger er nyere, så skal
                  du markere ”nybyggeri” og ”nyere” under Boligstand og vælge
                  stjernen ud for Boligstand. Nu har du fortalt os, at det er en
                  af dine vigtige præferencer.
                </p>
                <p>
                  Udover at sætte en oplysning som en præference kan du også
                  vægte hver enkelt præference. Det gør du under ”Præferencer”-
                  der har du mulighed for at sætte en vægtning mellem 0 og 5,
                  hvor 5 betyder det at det er et ufravigeligt krav fra din
                  side.
                </p>
                <p>
                  OBS. Hvis du sætter noget til 5 betyder det også, at alt der
                  ikke lever op til denne præference får du ikke vist, selvom
                  boligen passer på alle andre fronter.
                </p>
              </Typography>
            </Box>
            <Divider sx={{ my: 8, color: "#F1EEE7" }} />

            <Typography variant="h6" component="h5" fontWeight={600}>
              Er det stadig lidt uklart?
            </Typography>
            <Typography sx={{ mt: 2 }}>
              Du er velkommen til at ringe eller skrive til mig, så forklarer
              jeg det gerne for dig!
            </Typography>
            <Box sx={{ mt: 8 }}>
              <Box sx={{ display: "flex", alignItems: "flex-start", gap: 8 }}>
                <Box sx={{ mb: 8 }}>
                  <Avatar
                    alt=""
                    src={pic.agentfemale}
                    sx={{ width: 110, height: 110, mb: 5 }}
                  />
                </Box>
                <Box>
                  <Typography fontWeight={500} fontSize={16}>
                    Julie Bang-Møller
                  </Typography>
                  <Typography sx={{ mb: 2 }}>Boligagten</Typography>
                  <Link
                    href={`mailto:${Email}`}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 2,
                      mb: 1,
                    }}
                  >
                    <Email fontSize="small" />
                    info@nextkey.dk
                  </Link>
                  <Link
                    href={`tel:70605970`}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 2,
                    }}
                  >
                    <Phone fontSize="small" />
                    70605970
                  </Link>
                </Box>
              </Box>
            </Box>
          </Box>
        </Transition>
      </Modal>
    </>
  );
};

export default HelpPopup;
