import axios from "../../utils/api";
import { PasswordReset } from "./types";
import { AxiosResponse } from "axios";

const useUser = () => {
  const requestPasswordLink = async (data: PasswordReset): Promise<boolean> => {
    const response = await axios.post<
      any,
      AxiosResponse<any, any>,
      PasswordReset
    >("/user/?action=forgotpwd", data);

    const { Status } = response.data;
    if (Status !== "OK") {
      throw new Error(Status);
    } else {
      // fetch new user info.
      return true;
    }
  };

  return { requestPasswordLink };
};

export default useUser;
