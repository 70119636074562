import { Box } from "@mui/material";
import React from "react";
import styled from "styled-components";
import { UnitType } from "../type";
import { getFrontImage } from "../utils";

const StyledBanner = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: relative;
  border-radius: 10px 10px 0 0;

  &::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 10px 10px 0 0;
    background: linear-gradient(
      180deg,
      rgba(36, 74, 93, 0.25) 0%,
      rgba(36, 74, 93, 0.75) 100%
    );
    z-index: 1;
  }
`;

const StyledImage = styled("img")`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: 10px 10px 0 0;
  object-fit: cover;
  z-index: 0;
`;

const StyledHeadline = styled(Box)`
  position: relative;
  font-family: "Prata", "Poppins", Arial, sans-serif;
  color: #fff;
  z-index: 2;
`;

const UnitBanner: React.FC<{ unit: UnitType }> = ({ unit }) => {
  const image = getFrontImage(unit);

  return (
    <StyledBanner
      sx={{
        minHeight: {
          lg: 465,
          xs: 300,
        },
        p: {
          xl: 10,
          lg: 8,
          xs: 5,
        },
      }}
    >
      {image && <StyledImage alt="" src={image} />}
      <StyledHeadline
        component="h1"
        fontWeight={400}
        mb={0}
        lineHeight={1.2}
        pl={{
          xl: 10,
          lg: 8,
          md: 6,
          xs: 5,
        }}
        fontSize={{
          xl: 72,
          lg: 48,
          md: 32,
          xs: 24,
        }}
      >
        {unit.Headline}
      </StyledHeadline>
    </StyledBanner>
  );
};

export default UnitBanner;
