import { Box, Grid } from "@mui/material";
import React, { useState } from "react";
import "swiper/swiper.min.css";
import ModalImageSlider from "../../../components/ModalImageSlider";
import { UnitType } from "../type";

const UnitGallery: React.FC<{ unit: UnitType }> = ({ unit }) => {
  const [open, setOpen] = useState(false);
  const [active, setActive] = useState(0);

  const handleOpen = (ind: number) => {
    setOpen(true);
    setActive(ind);
  };
  const handleClose = () => setOpen(false);

  return (
    <>
      <Grid container spacing={1.5}>
        {unit.Images.map((image, i) => (
          <Grid item key={i} xl={3} lg={4} xs={6}>
            <Box
              sx={{ height: 250, backgroundColor: "#ddd", cursor: "pointer" }}
              onClick={() => {
                handleOpen(i);
              }}
            >
              <Box
                component="img"
                src={image.Thumbnail}
                alt=""
                sx={{
                  objectFit: "cover",
                  width: "100%",
                  height: "100%",
                  borderRadius: "5px",
                }}
              />
            </Box>
          </Grid>
        ))}
      </Grid>
      <ModalImageSlider
        open={open}
        handleClose={handleClose}
        images={unit.Images.map((image) => image.Url)}
        active={active}
      />
    </>
  );
};

export default UnitGallery;
