import { Shadows } from "@mui/material/styles/shadows";

function createShadow() {
  return `0px 1px 2px 0px rgba(8, 32, 44, 0.05);`;
}

const shadows: Shadows = [
  "none",
  createShadow(),
  createShadow(),
  createShadow(),
  createShadow(),
  createShadow(),
  createShadow(),
  createShadow(),
  createShadow(),
  createShadow(),
  "0px 4px 14px rgba(53, 64, 82, 0.1)",
  createShadow(),
  createShadow(),
  createShadow(),
  createShadow(),
  createShadow(),
  createShadow(),
  createShadow(),
  createShadow(),
  createShadow(),
  createShadow(),
  createShadow(),
  createShadow(),
  createShadow(),
  createShadow(),
];

export default shadows;
