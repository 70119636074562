import {
  Avatar,
  Box,
  Typography,
  Card,
  CardContent,
  Divider,
  Link,
} from "@mui/material";
import MdPhone from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";

type CardProps = {
  photo: string;
  name: string;
  title: string;
  email: string;
  phone: number;
};

const CardContact = ({ photo, name, title, email, phone }: CardProps) => {
  const Photo = photo;
  const Name = name;
  const Title = title;
  const Email = email;
  const Phone = phone;
  return (
    <>
      <Card sx={{ textAlign: "center", height: "100%" }}>
        <CardContent sx={{ px: 5, pt: 10 }}>
          <Avatar
            alt=""
            src={Photo}
            sx={{ width: 205, height: 205, mx: "auto", mb: 5 }}
          />
          <Typography fontWeight={500} mb={0}>
            {Name}
          </Typography>
          <Typography sx={{ fontWeight: 400 }}>{Title}</Typography>
        </CardContent>
        <Divider sx={{ my: 2, color: "#F1EEE7" }} />
        <CardContent
          sx={{
            px: 5,
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography fontWeight={500}>Skriv eller ring til mig</Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              width: "fit-content",
              mt: 2,
            }}
          >
            <Box>
              <Link
                href={`mailto:${Email}`}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 4,
                }}
              >
                <EmailIcon fontSize="small" />
                {Email}
              </Link>
            </Box>
            <Box>
              <Link
                href={`tel:${Phone}`}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 4,
                }}
              >
                <MdPhone fontSize="small" />
                {Phone}
              </Link>
            </Box>
          </Box>
        </CardContent>
      </Card>
    </>
  );
};

export default CardContact;
