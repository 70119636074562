import { AxiosResponse } from "axios";
import axios from "../../utils/api";
import { UnitType } from "./type";

export const getFrontThumbnail = (unit: UnitType): string => {
  let image = "";
  if (unit.Images) {
    const img = unit.Images.find((_img) => _img.IsFront);
    image = img ? img.Thumbnail : "";
  }
  return image;
};

export const getFrontImage = (unit: UnitType): string => {
  let image = "";
  if (unit.Images) {
    const img = unit.Images.find((_img) => _img.IsFront);
    image = img ? img.Url : "";
  }
  return image;
};

export const getSingleUnit = async (
  id: number
): Promise<UnitType | undefined> => {
  try {
    const response = await axios.get<any, AxiosResponse<UnitType, any>, any>(
      `/user/matchedunits/${id.toString()}`
    );
    return response.data;
  } catch (error) {}
};
