import { Box, Typography } from "@mui/material";
import React from "react";
import { Route, Routes } from "react-router-dom";
import { ProfileUserPage } from ".";
import banner from "../../assets/images/banner-2.jpg";
import ImageBanner from "../../components/ImageBanner";
import MainContainer from "../../components/MainContainer";
import Navbar from "./components/Navbar";
import { DynamicPage } from "./pages/DynamicPage";

const ProfilePageLayout: React.FC = ({ children }) => {
  return (
    <MainContainer>
      {/* banner */}
      <ImageBanner img={banner} />
      <Box height={{ lg: 38, xs: 24 }} />

      <Typography
        variant="h2"
        component="h2"
        sx={{
          fontFamily: "Helvetica, Poppins, Arial",
          letterSpacing: "0.1em",
          mb: 6,
        }}
      >
        Medlemsprofil
      </Typography>

      {/* navbar */}
      <Navbar />

      <Box height={40} />

      {/* {children} */}
      {/* <Outlet /> */}
      <Routes>
        <Route path="user" element={<ProfileUserPage />} />
        <Route path=":id" element={<DynamicPage />} />
      </Routes>
    </MainContainer>
  );
};

export default ProfilePageLayout;
