import { Box, Chip } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { AnswerType, OptionType, QuestionType } from "../types";
import FavoriteIconButton from "./FavoriteIconButton";
import useProfile from "../useProfile";
import _ from "lodash";

const OptionChip = styled(Chip)`
  height: 28px;
  font-size: 12px;
  font-weight: 400;
  border-radius: 4px;
  margin-right: 8px;
  margin-bottom: 0;

  .MuiChip-label {
    padding-left: 15px;
    padding-right: 15px;
  }
`;

type FieldChipsProps = {
  question: QuestionType;
  chipWidth: number;
  handleChipWidth: (width: number) => void;
};

const FieldChips: React.FC<FieldChipsProps> = ({
  question,
  chipWidth,
  handleChipWidth,
}) => {
  const [isFavorite, setIsFavorite] = useState<boolean>(question.Favorite);
  const [selected, setSelected] = useState<Array<OptionType>>([]);
  const [init, setInit] = useState(false);
  const { update } = useProfile();

  const updateRef = useRef(
    _.debounce(async (data: AnswerType) => {
      // TODO: compare with previous data.
      await update([data]);
    }, 2000)
  );

  useEffect(() => {
    // set initial selected.
    if (question.Options) {
      setSelected(
        question.Options.reduce<Array<OptionType>>((filtered, option) => {
          if (option.Selected) {
            filtered.push(option);
          }
          return filtered;
        }, [])
      );
    }
    setIsFavorite(question.Favorite);
  }, [question]);

  const handleClick = (value: OptionType) => {
    if (selected.includes(value)) {
      setSelected(selected.filter((v) => v !== value));
    } else {
      setSelected([...selected, value]);
    }
    setInit(true);
  };

  const handleFavorite = () => {
    setInit(true);
    setIsFavorite(!isFavorite);
  };

  useEffect(() => {
    if (init) {
      updateRef.current.cancel();
      const data: AnswerType = {
        ID: Number(question.ID),
        Favorite: isFavorite ? 1 : 0,
        AnsweredValues: selected.map((item) => {
          return {
            ID: item.Value,
            Value: true,
          };
        }),
      };
      updateRef.current(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFavorite, init, selected]);

  return (
    <>
      {/* single chip with favorite button inside */}
      <Box>
        {question.Options &&
          question.Options.map((option, i) => {
            return (
              <OptionChip
                key={i}
                ref={(ref) => {
                  if (ref) {
                    if (ref.offsetWidth > chipWidth) {
                      handleChipWidth(ref.offsetWidth);
                    }
                  }
                }}
                sx={{
                  justifyContent: "flex-start",
                  minWidth: chipWidth,
                }}
                label={option.ShowValue}
                onClick={() => {
                  handleClick(option);
                }}
                variant={selected.includes(option) ? "filled" : "outlined"}
                color={selected.includes(option) ? "secondary" : "default"}
                icon={
                  <FavoriteIconButton
                    isActive={isFavorite}
                    onClick={(e) => {
                      // if (selected.includes(option)) {
                      // }
                      e.stopPropagation();
                      handleFavorite();
                    }}
                  />
                }
              />
            );
          })}
      </Box>
    </>
  );
};

export default FieldChips;
