import { green, grey } from "@mui/material/colors";
import { THEMES } from "../constants";

const customBlue = {
  50: "#e5e9ec",
  100: "#bdc9ce",
  200: "#92a5ae",
  300: "#66808e",
  400: "#456575",
  500: "#244a5d",
  600: "#204355",
  700: "#1b3a4b",
  800: "#163241",
  900: "#08202C",
  A100: "#6dc0ff",
  A200: "#3aaaff",
  A400: "#0795ff",
  A700: "#0087ec",
};

const brun = {
  50: "#f3ede6",
  100: "#e2d1c0",
  200: "#cfb297",
  300: "#bb936d",
  400: "#ad7c4d",
  500: "#9E662D",
  600: "#965d29",
  700: "#8c5323",
  800: "#82491d",
  900: "#703712",
  A100: "#ffc6a6",
  A200: "#ffa573",
  A400: "#ff8540",
  A700: "#ff7426",
};

const darkBlue = "#081f2c";
const lightGreen = "#4CAF50";

const defaultVariant = {
  name: THEMES.DEFAULT,
  palette: {
    mode: "light",
    primary: {
      main: brun[500],
      contrastText: "#FFF",
    },
    secondary: {
      // TODO: change all darkBlue to secondary
      main: darkBlue,
      contrastText: "#FFF",
    },
    lightGreen: {
      main: lightGreen,
      contrastText: "#FFF",
    },
    darkBlue: {
      main: darkBlue,
      contrastText: "#FFF",
    },
    background: {
      default: "#F1EEE7",
      paper: "#FFF",
    },
  },
  header: {
    color: "#FFF",
    background: darkBlue,
    search: {
      color: grey[800],
    },
    indicator: {
      background: customBlue[600],
    },
  },
  divider: {
    background: "#F1EEE7",
  },
  footer: {
    color: grey[500],
    background: "#FFF",
  },
  sidebar: {
    color: brun[500],
    background: "#FCFAF9",
    header: {
      color: grey[200],
      background: darkBlue,
      brand: {
        color: customBlue[500],
      },
    },
    footer: {
      color: "#fff",
      background: darkBlue,
      online: {
        background: green[500],
      },
    },
    badge: {
      color: "#FFF",
      background: darkBlue,
    },
  },
  profileNavbar: {
    color: "#fff",
    background: "#DBCBB9",
    active: { color: "#fff", background: "#9E652E" },
  },
};

const variants: Array<VariantType> = [defaultVariant];

export default variants;

export type VariantType = {
  name: string;
  palette: {
    primary: MainContrastTextType;
    secondary: MainContrastTextType;
    darkBlue: MainContrastTextType;
    lightGreen: MainContrastTextType;
  };
  header: ColorBgType & {
    search: {
      color: string;
    };
    indicator: {
      background: string;
    };
  };
  footer: ColorBgType;
  sidebar: ColorBgType & {
    header: ColorBgType & {
      brand: {
        color: string;
      };
    };
    footer: ColorBgType & {
      online: {
        background: string;
      };
    };
    badge: ColorBgType;
  };
  profileNavbar: ColorBgType & {
    active: ColorBgType;
  };
};

type MainContrastTextType = {
  main: string;
  contrastText: string;
};
type ColorBgType = {
  color: string;
  background: string;
};

declare module "@mui/material/styles" {
  interface Palette {
    darkBlue: Palette["primary"];
    lightGreen: Palette["primary"];
  }

  // allow configuration using `createTheme`
  interface PaletteOptions {
    darkBlue?: PaletteOptions["primary"];
    lightGreen?: PaletteOptions["primary"];
  }
}

// Update color prop options
declare module "@mui/material/Chip" {
  interface ChipPropsColorOverrides {
    darkBlue: true;
    lightGreen: true;
  }
}
declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    darkBlue: true;
    lightGreen: true;
  }
}
declare module "@mui/material/IconButton" {
  interface IconButtonPropsColorOverrides {
    darkBlue: true;
  }
}
