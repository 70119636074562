import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../redux/store";
import axios from "../../utils/api";
import { UserInfoType } from "./types";

export interface UserState {
  data: null | UserInfoType;
  avatar: string | undefined;
  status: "idle" | "initialized" | "loading" | "failed";
}

const initialState: UserState = {
  data: null,
  avatar: "",
  status: "idle",
};

export const fetchInfo = createAsyncThunk<any, boolean, { state: RootState }>(
  "user/fetchData",
  async (_force: boolean = false) => {
    try {
      const response = await axios.get("/user/");
      return response.data ? response.data : null;
    } catch (error) {}
  },
  {
    condition: (force: boolean = false, { getState }) => {
      const { user } = getState();
      if (
        user.status === "loading" ||
        (user.status === "initialized" && !force)
      ) {
        return false;
      }
    },
  }
);

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    // clear user data.
    clear: (state) => {
      state.data = null;
      state.status = "idle";
    },
    updateAvatar: (state) => {
      state.avatar = state.data?.Avatar;
    },
    forceAvatarCache: (state) => {
      const hash = Date.now().toString();
      state.avatar = `${state.data?.Avatar}?${hash}`;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchInfo.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(fetchInfo.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchInfo.fulfilled, (state, action) => {
        state.status = "initialized";
        state.data = action.payload;
        state.avatar = state.data?.Avatar;
      });
  },
});

export const { clear, forceAvatarCache } = userSlice.actions;

export const selectUserInfo = (state: RootState) => state.user.data;
export const selectUserAvatar = (state: RootState) => state.user.avatar;

export default userSlice.reducer;
