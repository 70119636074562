import React from "react";
import Shimmer from "../../../components/Shimmer";
import { Box, Grid } from "@mui/material";

const UnitShimmer = () => {
  return (
    <>
      <Box
        sx={{
          position: "relative",
          minHeight: {
            lg: 465,
            xs: 300,
          },
        }}
      >
        <Shimmer></Shimmer>&nbsp;
      </Box>
      <Box
        p={{
          xl: 16,
          lg: 10,
          md: 8,
          xs: 5,
        }}
      >
        <Grid container>
          <Grid item xs={6}>
            <Box
              sx={{
                position: "relative",
                height: 300,
              }}
            >
              <Shimmer></Shimmer>&nbsp;
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box
              sx={{
                position: "relative",
                height: 300,
              }}
            >
              <Shimmer></Shimmer>&nbsp;
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default UnitShimmer;
