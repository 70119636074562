import { Box, Card, CardActions, CardContent } from "@mui/material";
import React from "react";
import Shimmer from "../../../components/Shimmer";

const UnitCardShimmer = () => {
  return (
    <Card sx={{ height: "100%" }}>
      <Box height={155} sx={{ position: "relative" }}>
        <Shimmer></Shimmer>&nbsp;
      </Box>
      <CardContent>
        {/* address */}
        <Box sx={{ position: "relative", mb: 2.5 }}>
          <Shimmer></Shimmer>&nbsp;
        </Box>
        {/* info */}
        <Box mb={2}>
          <Box
            mb={2}
            sx={{
              width: 70,
              mr: 2,
              mb: 2,
              position: "relative",
              display: "inline-block",
            }}
          >
            <Shimmer></Shimmer>&nbsp;
          </Box>
          <Box
            mb={2}
            sx={{
              width: 70,
              mr: 2,
              mb: 2,
              position: "relative",
              display: "inline-block",
            }}
          >
            <Shimmer></Shimmer>&nbsp;
          </Box>
        </Box>
        {/* headline */}
        <Box sx={{ position: "relative" }}>
          <Shimmer></Shimmer>&nbsp;
        </Box>
      </CardContent>
      <CardActions
        sx={{
          borderTop: "1px solid #F0EDE7",
          height: 65,
        }}
      ></CardActions>
    </Card>
  );
};

export default UnitCardShimmer;
