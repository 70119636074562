import {
  CardContent as MuiCardContent,
  CardContentProps,
  Typography,
} from "@mui/material";
import React from "react";

export const CardContent: React.FC<CardContentProps> = (props) => {
  return (
    <MuiCardContent
      {...props}
      sx={{
        p: {
          md: 8,
          xs: 6,
        },
      }}
    >
      {props.children}
    </MuiCardContent>
  );
};

export const CardTitle: React.FC<any> = ({ children, ...props }) => {
  return (
    <Typography variant="h5" component="h2" mb={6} {...props}>
      {children}
    </Typography>
  );
};
