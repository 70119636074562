import { LockOpen } from "@mui/icons-material";
import { Typography } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import styled from "styled-components/macro";
import { useAuth } from "../../modules/auth";
import { selectUserInfo } from "../../modules/profile";

const Footer = styled.div`
  background-color: ${(props) =>
    props.theme.sidebar.footer.background} !important;
  padding: ${(props) => props.theme.spacing(2.75)}
    ${(props) => props.theme.spacing(4)};
  border-right: 1px solid rgba(0, 0, 0, 0.12);
`;

const FooterText = styled(Typography)`
  color: ${(props) => props.theme.sidebar.footer.color};
`;

const SidebarFooter: React.FC = ({ ...rest }) => {
  const { signOut } = useAuth();
  const user = useSelector(selectUserInfo);
  const navigate = useNavigate();

  const handleSignOut = async () => {
    await signOut();
    navigate("/auth");
  };

  return (
    <Footer {...rest}>
      <FooterText variant="body2" fontWeight="bold">
        {user?.FirstName} {user?.LastName}
      </FooterText>
      <FooterText onClick={handleSignOut} sx={{ cursor: "pointer" }}>
        <LockOpen sx={{ fontSize: 14, mt: 1 }} /> Log ud
      </FooterText>
    </Footer>
  );
};

export default SidebarFooter;
