import { ArrowBack, ArrowForward, Close } from "@mui/icons-material";
import { Box, IconButton, Modal } from "@mui/material";
import React from "react";
import { Swiper, SwiperSlide, useSwiper } from "swiper/react/swiper-react";
import "swiper/swiper.min.css";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1200,
  maxWidth: "90%",
  maxHeight: "90%",
  boxShadow: "0px 4px 14px rgba(53, 64, 82, 0.1)",
};

type ModalImageSliderProps = {
  open: boolean;
  handleClose: () => void;
  images: string[];
  active?: number;
};

const SlideNextButton = () => {
  const swiper = useSwiper();

  return (
    <Box
      sx={{
        position: "absolute" as "absolute",
        right: 20,
        top: "50%",
        marginTop: "-20px",
        zIndex: 10,
      }}
    >
      <IconButton onClick={() => swiper.slideNext()}>
        <ArrowForward sx={{ color: "#fff" }} />
      </IconButton>
    </Box>
  );
};

const SlidePrevButton = () => {
  const swiper = useSwiper();

  return (
    <Box
      sx={{
        position: "absolute" as "absolute",
        left: 20,
        top: "50%",
        marginTop: "-20px",
        zIndex: 10,
      }}
    >
      <IconButton onClick={() => swiper.slidePrev()}>
        <ArrowBack sx={{ color: "#fff" }} />
      </IconButton>
    </Box>
  );
};

const ModalImageSlider: React.FC<ModalImageSliderProps> = ({
  open,
  handleClose,
  images,
  active,
}) => {
  return (
    <Modal open={open} onClose={handleClose}>
      <>
        <Box
          sx={{
            position: "absolute",
            right: 20,
            top: 20,
          }}
        >
          <IconButton onClick={handleClose}>
            <Close sx={{ color: "#fff" }} />
          </IconButton>
        </Box>
        <Box sx={style}>
          <Swiper
            slidesPerView={1}
            spaceBetween={20}
            loop={true}
            navigation
            initialSlide={active ?? 0}
          >
            <SlideNextButton />
            <SlidePrevButton />
            {images &&
              images.map((img, i) => (
                <SwiperSlide key={i}>
                  <Box>
                    <img src={img} alt="" />
                  </Box>
                </SwiperSlide>
              ))}
          </Swiper>
        </Box>
      </>
    </Modal>
  );
};

export default ModalImageSlider;
