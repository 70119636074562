import { Card, Grid, Typography } from "@mui/material";
import { CardContent, CardTitle } from "../components/Card";
import SectionTitle from "../components/SectionTitle";
import UserInfoForm from "../components/UserInfoForm";
import useUser from "../useUser";

function ProfileUserPage() {
  const { info } = useUser();

  return (
    <>
      <SectionTitle>Bruger</SectionTitle>

      <Grid container mb={8}>
        <Grid item xl={6} lg={8}>
          <Typography fontWeight="500">
            Udfyld dine oplysninger, så vi kan kontakte dig
          </Typography>
          <Typography>
            Vi kontakter dig kun når vi har boligmatches klar, der svarer til
            din medlemsprofil.
          </Typography>
        </Grid>
      </Grid>

      <Card>
        <CardContent>
          <CardTitle>Dine oplysninger</CardTitle>

          <Grid container>
            <Grid item xl={6} lg={8}>
              {info && <UserInfoForm data={info} />}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
}

export default ProfileUserPage;
