import {
  AspectRatio,
  Event,
  GridView,
  OtherHouses,
  Pets,
} from "@mui/icons-material";
import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import React from "react";
import { UnitType } from "../type";

const UnitInfoBox: React.FC<{ unit: UnitType }> = ({ unit }) => {
  const info: Array<{ text: any; icon: any }> = [
    { text: unit?.Info.Text, icon: <OtherHouses color="primary" /> },
    { text: unit?.Info.Rooms, icon: <GridView color="primary" /> },
    { text: unit?.Info.M2, icon: <AspectRatio color="primary" /> },
    { text: unit?.Info.Pets, icon: <Pets color="primary" /> },
    { text: unit?.Info.AcquisitionDate, icon: <Event color="primary" /> },
  ];
  console.log(unit?.Info);
  return (
    <Box
      sx={{
        width: "320px",
        maxWidth: "100%",
        backgroundColor: "#f0ede7",
        borderRadius: " 5px",
      }}
    >
      <Box p={{ lg: 5 }}>
        {info.map((item, i) => (
          <List key={i}>
            <ListItem>
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText
                primary={
                  <Typography color="primary" fontWeight={400}>
                    {item.text}
                  </Typography>
                }
              />
            </ListItem>
          </List>
        ))}
      </Box>
      <Box
        p={5}
        sx={{
          bgcolor: "primary.main",
          borderRadius: "0 0 5px 5px",
          textAlign: "center",
        }}
      >
        <Typography color="#fff" fontWeight={500}>
          {unit?.Info.Money}
        </Typography>
      </Box>
    </Box>
  );
};

export default UnitInfoBox;
