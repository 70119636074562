import { makeStyles } from "@mui/styles";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from "@mui/material";
// import { Formik } from "formik";
// import { MatchedUnits } from "../../profile/types";
import CloseIcon from "@mui/icons-material/Close";

const useStyles = makeStyles((theme) => ({
  paper: {
    overflowY: "unset",
  },
  customizedButton: {
    position: "absolute",
    right: "-20px",
    top: "-20px",
    backgroundColor: "#091F2C",
    color: "#FFFFFF",
    "&:hover": {
      backgroundColor: "#204355 !important",
    },
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

export default function MatchDislikeDialog(props: any) {
  const classes = useStyles();
  return (
    <div>
      <Dialog
        open={props.open}
        onClose={props.onClose()}
        aria-labelledby="draggable-dialog-title"
        classes={{ paper: classes.paper }}
      >
        <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
          Subscribe
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Angiv en kommentar og hjælp os med at matche dig bedre boliger.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <IconButton
            aria-label="close"
            autoFocus
            onClick={props.onClose()}
            color="primary"
            className={classes.customizedButton}
          >
            <CloseIcon />
          </IconButton>
          <Button onClick={props.onClose()} color="primary">
            Subscribe
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
