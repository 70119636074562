import { Password } from "@mui/icons-material";
import {
  Alert,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Link,
  TextField,
  Typography,
} from "@mui/material";
import { Formik } from "formik";
import { JsonWebTokenError } from "jsonwebtoken";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import useAppDispatch from "../../../redux/useAppDispatch";
import useAppSelector from "../../../redux/useAppSelector";
// import { closeLoginModal, selectOpenLoginModal } from "../slice";
// import useAuth from "../useAuth";

function ResetPasswordForm({
  token,
  setSubmited,
}: {
  token: any;
  setSubmited: any;
}) {
  // const { signIn } = useAuth();

  // const isOpenModal = useAppSelector(selectOpenLoginModal);
  const dispatch = useAppDispatch();
  // const handleCloseModal = () => dispatch(closeLoginModal());

  return (
    <Formik
      initialValues={{
        password: "",
        passwordConfirm: "",
        submit: false,
      }}
      validationSchema={Yup.object().shape({
        password: Yup.string()
          .max(255)
          .required("Indtast dit kodeord")
          .oneOf(
            [Yup.ref("passwordConfirm"), null],
            "Begge adgangskoder skal matche"
          ),
        passwordConfirm: Yup.string()
          .max(255)
          .required("Indtast dit kodeord")
          .oneOf([Yup.ref("password"), null], "Begge adgangskoder skal matche"),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        fetch(process.env.REACT_APP_API_URL + "/user/?action=resetpwd", {
          method: "POST",
          headers: {
            Authorization: "Bearer " + token,
          },
          body: JSON.stringify({
            password: values.passwordConfirm,
          }),
          redirect: "follow",
        });
        setSubmited(true);
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <form noValidate onSubmit={handleSubmit}>
          {errors.submit && (
            <Alert sx={{ mb: 5 }} severity="warning">
              {errors.submit}
            </Alert>
          )}

          <TextField
            type="password"
            name="password"
            label="Ny adgangskode"
            value={values.password}
            error={Boolean(touched.password && errors.password)}
            fullWidth
            helperText={touched.password && errors.password}
            onBlur={handleBlur}
            onChange={handleChange}
          />
          <Box height={20} />

          <TextField
            type="password"
            name="passwordConfirm"
            label="Bekræft adgangskode"
            value={values.passwordConfirm}
            error={Boolean(touched.passwordConfirm && errors.passwordConfirm)}
            fullWidth
            helperText={touched.passwordConfirm && errors.passwordConfirm}
            onBlur={handleBlur}
            onChange={handleChange}
          />

          <Box height={20} />

          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={isSubmitting}
            size="large"
          >
            ÆNDRE ADGANGSKODE
          </Button>

          <Box height={100} />
        </form>
      )}
    </Formik>
  );
}

export default ResetPasswordForm;
