import ImageBanner from "./ImageBanner";
import banner from "../assets/images/banner-1.jpg";
import { Box, Grid, Paper, Typography } from "@mui/material";
import CardContact from "./CardContact";
import MainContainer from "./MainContainer";
import * as pic from "../assets/UserImages/UserImageCollection";

function NeedHelp() {
  return (
    <MainContainer>
      <ImageBanner img={banner} />
      <Box height={30} />

      <Grid container spacing={8}>
        <Grid item lg={8} xl={6} xs={12}>
          <Paper sx={{ p: { lg: 10, xs: 5 }, height: "100%" }}>
            <Typography
              mb={4}
              fontFamily={"helvetica"}
              component="div"
              sx={{
                fontSize: "24px",
                fontFamily: "Helvetica",
                fontWeight: 400,
                lineHeight: "33px",
                letterSpacing: "0px",
                textAlign: "left",
              }}
            >
              Brug for hjælp?
            </Typography>

            <Typography
              mb={4}
              sx={{
                fontStyle: "Normal",
                fontFamily: "Poppins",
                textAlign: "left",
              }}
            >
              Har du spørgsmål til dine boligmatches eller har du brug for mere
              vejledning med din boligsøgning, så står vi klar til at hjælpe.
              Ring eller skriv til en af vores boligagenter, som er dine guides
              gennem boligmarkedet. Vi vil besvare dine eventuelle spørgsmål
              hurtigst muligt.
            </Typography>
          </Paper>
        </Grid>
        <Grid item lg={4} xl={3} xs={12}>
          <CardContact
            photo={pic.agentfemale}
            name={"Julie Bang-Møller"}
            title={"Boligagent"}
            email={"info@nextkey.dk"}
            phone={70605970}
          />
        </Grid>
        <Grid item lg={4} xl={3} xs={12}>
          <CardContact
            photo={pic.agentmale}
            name={"Daniel B. W. Schmidt"}
            title={"Boligagent"}
            email={"info@nextkey.dk"}
            phone={70605970}
          />
        </Grid>
      </Grid>
    </MainContainer>
  );
}

export default NeedHelp;
