import { createGlobalStyle } from "styled-components/macro";
import { GlobalStyleProps } from "./styles";

const GlobalStyle = createGlobalStyle<GlobalStyleProps>`
  html,
  ,
  #root {
    height: 100%;
    overflow-x: hidden;
  }

  body {
    background: ${(props) => {
      return props.theme.palette.background.default;
    }};
    margin: 0;
    color: ${(props) => props.theme.palette.darkBlue.main};
  }

  img {
    max-width: 100%;
  }

  .MuiCardHeader-action .MuiIconButton-root {
    padding: 4px;
    width: 28px;
    height: 28px;
  }
`;

export default GlobalStyle;
