import { Box, Typography } from "@mui/material";
import React from "react";
import { UnitType } from "../type";
import CollapseBox from "./BoxCollapse";

const UnitEconomyBox: React.FC<{ unit: UnitType }> = ({ unit }) => {
  return (
    <>
      <CollapseBox title="Økonomi">
        {console.log(unit.Economy)}
        {unit.Economy &&
          unit.Economy.Money.map((item, i) => (
            <Box
              key={i}
              sx={{
                display: "flex",
                my: 4,
                justifyContent: "space-between",
              }}
            >
              <Typography>{item.Title}</Typography>
              <Typography>{item.Value}</Typography>
            </Box>
          ))}
        {unit.Economy.Money[0].Title === "Leje pr. md." && (
          <>
            <hr />
            {unit.Economy.Other.map((item, i) => (
              <Box
                key={i}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  my: 4,
                }}
              >
                <Typography>{item.Title}</Typography>
                <Typography>{item.Value}</Typography>
              </Box>
            ))}
          </>
        )}
      </CollapseBox>
    </>
  );
};

export default UnitEconomyBox;
