import { Box, Grid, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import MainContainer from "../../../components/MainContainer";
import UnitCard from "../components/UnitCard";
import UnitCardShimmer from "../components/UnitCardShimmer";
import useUnits from "../useUnits";
import MatchDislikeDialog from "../components/MatchDislikeDialog";
import { UnitType } from "../type";

const UnitsPage = () => {
  const [openDislikeDialog, setOpen] = useState(false);
  const { units, isLoading } = useUnits(true);
  const [allUnits, setAllUnits] = useState<UnitType[]>([]);
  const [vacantUnits, setVacantUnits] = useState<UnitType[]>([]);
  const [soldUnits, setSoldUnits] = useState<UnitType[]>([]);

  useEffect(() => {
    setVacantUnits(
      units.filter(
        (unit) => unit.StatusGroupID >= 10 && unit.StatusGroupID <= 50
      )
    );
    setSoldUnits(
      units.filter(
        (unit) => unit.StatusGroupID === 80 || unit.StatusGroupID === 90
      )
    );
  }, [units]);

  useEffect(() => {
    setAllUnits([...vacantUnits, ...soldUnits]);
  }, [soldUnits, vacantUnits]);

  const handleClose = () => {
    setOpen(false);
  };

  const onDislike = () => {
    //dislike unit
    setOpen(true);
  };

  const handleRemove = (unitId: number) => {
    setSoldUnits((units) => units.filter((unit) => unit.UnitID !== unitId));
    setAllUnits((units) => units.filter((unit) => unit.UnitID !== unitId));
  };

  return (
    <MainContainer>
      {openDislikeDialog && (
        <MatchDislikeDialog
          onClose={() => handleClose}
          open={openDislikeDialog}
        />
      )}
      <Typography
        variant="h2"
        component="h2"
        sx={{
          fontFamily: "Helvetica, Poppins, Arial",
          letterSpacing: "0.1em",
          mb: 6,
        }}
      >
        Nyeste boligmatches
      </Typography>

      <Typography mb={8}>Her finder du dine boligmatches</Typography>

      <Grid container spacing={8}>
        {isLoading ? (
          <>
            {[0, 1, 2, 3].map((_, i) => (
              <Grid key={i} item xs={12} sm={6} xl={3} lg={4} md={6}>
                <UnitCardShimmer />
              </Grid>
            ))}
          </>
        ) : (
          <>
            {vacantUnits.map((unit, i) => {
              return (
                unit.IsNewMatch && (
                  <Grid key={i} item xs={12} xl={3} lg={4} md={6}>
                    <UnitCard unit={unit} onDislike={onDislike} />
                  </Grid>
                )
              );
            })}
          </>
        )}
      </Grid>

      <Box height={50} />

      <Typography
        variant="h2"
        component="h2"
        sx={{
          fontFamily: "Helvetica, Poppins, Arial",
          letterSpacing: "0.1em",
          mb: 6,
        }}
      >
        Udlejede/solgte boligmatches
      </Typography>

      <Grid container spacing={8}>
        {isLoading ? (
          <>
            {[0, 1, 2, 3].map((_, i) => (
              <Grid key={i} item xs={12} sm={6} xl={3} lg={4} md={6}>
                <UnitCardShimmer />
              </Grid>
            ))}
          </>
        ) : (
          <>
            {soldUnits.map((unit, i) => {
              return (
                <Grid key={i} item xs={12} xl={3} lg={4} md={6}>
                  <UnitCard
                    unit={unit}
                    onDislike={onDislike}
                    onRemove={handleRemove}
                  />
                </Grid>
              );
            })}
          </>
        )}
      </Grid>

      <Box height={50} />

      <Typography
        variant="h2"
        component="h2"
        sx={{
          fontFamily: "Helvetica, Poppins, Arial",
          letterSpacing: "0.1em",
          mb: 6,
        }}
      >
        Alle boligmatches
      </Typography>

      <Grid container spacing={8}>
        {isLoading ? (
          <>
            {[0, 1, 2, 3].map((_, i) => (
              <Grid key={i} item xs={12} sm={6} xl={3} lg={4} md={6}>
                <UnitCardShimmer />
              </Grid>
            ))}
          </>
        ) : (
          <>
            {allUnits.map((unit, i) => {
              return (
                <Grid key={i} item xs={12} sm={6} xl={3} lg={4} md={6}>
                  <UnitCard unit={unit} onDislike={() => onDislike()} />
                </Grid>
              );
            })}
          </>
        )}
      </Grid>
    </MainContainer>
  );
};

export default UnitsPage;
