import React, { useEffect, useState } from "react";
import { GroupType, QuestionType } from "../types";
import useProfile from "../useProfile";
import { parseQuestionGroup } from "../utils";
import QuestionGroupCard from "./QuestionGroupCard";
import QuestionsShimmer from "./QuestionsShimmer";

type QuestionGroupProps = {
  group: GroupType;
  showTitle?: boolean;
};

// show group of questions for each card, separated by Line type
const QuestionGroup: React.FC<QuestionGroupProps> = ({ showTitle, group }) => {
  const { data, status } = useProfile();
  const [questionGroup, setQuestionGroup] = useState<
    Array<Array<QuestionType>>
  >([]);

  useEffect(() => {
    if (group) {
      setQuestionGroup(parseQuestionGroup(group));
    }
  }, [data, group]);
  return (
    <>
      {status === "loading" && !data ? (
        <QuestionsShimmer />
      ) : (
        <>
          {questionGroup.map((questions, i) => (
            <QuestionGroupCard
              key={i}
              questions={questions}
              cardTitle={showTitle ? group?.Name : ""}
            />
          ))}
        </>
      )}
    </>
  );
};

export default QuestionGroup;
