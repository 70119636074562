import { Box, Paper, Typography } from "@mui/material";
import React from "react";
import styled from "styled-components/macro";
import img from "../../../assets/images/login-form.jpg";
import logo from "../../../assets/images/logo.png";
import LoginForm from "../components/LoginForm";

const Picture = styled("picture")`
  display: block;
  width: 100%;
  height: 100%;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const LoginContent = () => {
  return (
    <Paper
      sx={{
        display: "flex",
        maxWidth: 1000,
        width: "100%",
        minHeight: {
          xs: 0,
          sm: 630,
        },
        borderRadius: 2.5,
        bgcolor: "#fff",
      }}
    >
      <Box
        sx={{
          display: {
            xs: "none",
            sm: "block",
          },
          width: {
            xs: "none",
            sm: "30%",
            md: "50%",
          },
          borderRadius: 2.5,
          borderTopRightRadius: 0,
          borderBottomRightRadius: 0,
          overflow: "hidden",
        }}
      >
        <Picture>
          <img src={img} alt="" />
        </Picture>
      </Box>
      <Box
        p={5}
        pb={10}
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          width: {
            xs: "100%",
            sm: "70%",
            md: "50%",
          },
        }}
      >
        {/* Logo */}
        <Box textAlign="center" pt={{ xs: 5, lg: 20 }} maxWidth={363} mx="auto">
          <Box mb={{ xs: 4, md: 8 }}>
            <img src={logo} alt="" />
          </Box>
          <Typography fontWeight={300}>
            Velkommen til Nextkey. All-in-one-udlejningspartner der varetager
            udlejningen af jeres boligmasse og sikre en 5-stjernet håndtering af
            jeres potentielle ejere.
          </Typography>
        </Box>
        <Box maxWidth={280} width="100%" mx="auto" mt={10}>
          <LoginForm />
        </Box>
      </Box>
    </Paper>
  );
};

export default LoginContent;
