import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Alert,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  TextField,
  Typography,
} from "@mui/material";
import { Formik } from "formik";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import usePassword from "../usePassword";

function ForgotPasswordForm() {
  const navigate = useNavigate();
  const { requestPasswordLink } = usePassword();
  return (
    <Formik
      initialValues={{
        email: "",
        username: "",
        password: "",
        terms: false,
        submit: false,
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .email("Emailen skal indholde et @")
          .max(255)
          .required("Indtast din email"),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          await requestPasswordLink({ Email: values.email });

          // close modal.
          navigate("/auth");
        } catch (error: any) {
          const message = error.message || "Something went wrong";

          setStatus({ success: false });
          setErrors({ submit: message });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
        setFieldValue,
      }) => (
        <form noValidate onSubmit={handleSubmit}>
          {errors.submit && (
            <Alert sx={{ mb: 5 }} severity="warning">
              {errors.submit}
            </Alert>
          )}

          <TextField
            type="email"
            name="email"
            label="E-mail"
            value={values.email}
            error={Boolean(touched.email && errors.email)}
            fullWidth
            helperText={touched.email && errors.email}
            onBlur={handleBlur}
            onChange={handleChange}
          />

          <Box height={24} />

          <Button
            type="submit"
            fullWidth
            variant="contained"
            // color="primary"
            disabled={isSubmitting || !values.email}
            size="large"
          >
            SEND LINK
          </Button>

          <Box height={12} />
        </form>
      )}
    </Formik>
  );
}

export default ForgotPasswordForm;
