import { Typography, Avatar, Card, CardContent, Box } from "@mui/material";
import { Link } from "react-router-dom";
import useUser from "../modules/profile/useUser";
import CEO from "../assets/images/ceo.jpg";

const DashboardProfileCard = () => {
  const { info } = useUser();

  return (
    <>
      <Card sx={{ textAlign: "center", height: "100%" }}>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography
            variant="h5"
            sx={{
              p: 4,
              textAlign: "left",
              width: "fit-content",
            }}
          >
            Profil
          </Typography>
          <Typography
            component={Link}
            to="user-account"
            variant="body1"
            fontSize={12}
            sx={{
              textAlign: "right",
              color: (theme) => theme.palette.grey[300],
              p: 2,
              textDecoration: "none",
            }}
          >
            Rediger
          </Typography>
        </Box>
        <CardContent sx={{ px: 5, pt: 0 }}>
          <Avatar
            alt=""
            src={info?.Avatar}
            sx={{ width: 170, height: 170, mx: "auto", mb: 5 }}
          />
          <Typography variant="h4" fontWeight={500} mb={0}>
            {info?.FirstName} {info?.LastName}
          </Typography>
          {/* <Typography sx={{ fontWeight: 400 }}>
            Lorem Ipsum Dolot Sit
          </Typography> */}
        </CardContent>
      </Card>
    </>
  );
};

export default DashboardProfileCard;
