import { Chip, Typography, Card, Box, CardContent } from "@mui/material";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { GroupType } from "../modules/profile/types";
import { useProfile } from "../modules/profile";

const DashboardPreferenceCard: React.FC<any> = () => {
  const [preferences, setPreferences] = useState<GroupType>();
  const { data } = useProfile();
  useEffect(() => {
    if (data) {
      // 1. by ID
      setPreferences(data.Groups.find((_group) => _group.GroupID === 804));

      // 2. or by route name
      setPreferences(
        data.Groups.find((_group) => _group.RouteName === "praeferencer")
      );
    }
  }, [data]);
  return (
    <>
      <Card sx={{ textAlign: "center", height: "100%" }}>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography
            variant="h5"
            sx={{
              p: 4,
              textAlign: "left",
              width: "fit-content",
            }}
          >
            Præferencer
          </Typography>
          <Typography
            component={Link}
            to={"profile/" + preferences?.RouteName}
            variant="body1"
            fontSize={12}
            sx={{
              textAlign: "right",
              color: (theme) => theme.palette.grey[300],
              p: 2,
              textDecoration: "none",
            }}
          >
            Rediger
          </Typography>
        </Box>
        <CardContent sx={{ px: 5 }}>
          <Box
            sx={{
              justifyContent: "space-between",
            }}
          >
            {" "}
            {preferences?.Questions.map((item) => {
              return (
                <Chip
                  label={item.Prefix}
                  sx={{
                    backgroundColor: "#CFB297",
                    fontWeight: 450,
                    mb: 2,
                    mr: 2,
                    borderRadius: 2,
                  }}
                />
              );
            })}
          </Box>
        </CardContent>
      </Card>
    </>
  );
};

export default DashboardPreferenceCard;
