import {
  Alert,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Link,
  TextField,
  Typography,
} from "@mui/material";
import { Formik } from "formik";
import React from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import useAppDispatch from "../../../redux/useAppDispatch";
import useAppSelector from "../../../redux/useAppSelector";
import { closeLoginModal, selectOpenLoginModal } from "../slice";
import useAuth from "../useAuth";

function LoginForm() {
  const navigate = useNavigate();
  const { signIn, ActivationMail } = useAuth();

  const isOpenModal = useAppSelector(selectOpenLoginModal);
  const dispatch = useAppDispatch();
  const handleCloseModal = () => dispatch(closeLoginModal());

  return (
    <Formik
      initialValues={{
        email: "",
        password: "",
        terms: false,
        submit: false,
        statusCode: 0,
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string().max(255).required("Indtast dit brugernavn / email"),
        password: Yup.string().max(255).required("Indtast dit kodeord"),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          await signIn(values.email, values.password);

          // navigate or close modal.
          if (isOpenModal) {
            handleCloseModal();
          } else {
            navigate("/");
          }
        } catch (error: any) {
          const message = error.message || "Something went wrong";
          const statusCode = error.statusCode || 0;
          setStatus({ success: false });
          setErrors({ submit: message, statusCode: statusCode });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        setErrors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
        setFieldValue,
      }) => (
        <form noValidate onSubmit={handleSubmit}>
          {errors.submit &&
            (errors.statusCode ? (
              <Alert
                sx={{
                  mb: 5,
                  cursor: "pointer",
                  fontSize: 10.5,
                }}
                severity="warning"
                onClick={async () => {
                  const data = await ActivationMail();
                  if (data.status === 200) {
                    setErrors({ submit: "Aktiveringsmailen er nu afsendt" });
                  }
                }}
              >
                {errors.submit}
              </Alert>
            ) : (
              <Alert
                sx={{
                  mb: 5,
                  fontSize: 11,
                  display: "flex",
                  alignItems: "center",
                  flexWrap: "wrap",
                }}
                severity="warning"
              >
                {errors.submit}
              </Alert>
            ))}

          <TextField
            type="email"
            name="email"
            label="Brugernavn / Email"
            value={values.email}
            error={Boolean(touched.email && errors.email)}
            fullWidth
            helperText={touched.email && errors.email}
            onBlur={handleBlur}
            onChange={handleChange}
          />
          <Box height={20} />

          <TextField
            type="password"
            name="password"
            label="Kodeord"
            value={values.password}
            error={Boolean(touched.password && errors.password)}
            fullWidth
            helperText={touched.password && errors.password}
            onBlur={handleBlur}
            onChange={handleChange}
          />
          <Box height={12} />

          <Typography fontWeight={500} fontSize={11} align={"right"}>
            Har du glemt dit kodeord?{" "}
            <Link component={RouterLink} to="/password/forgot">
              Nulstil her
            </Link>
          </Typography>
          <Box height={20} />

          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={isSubmitting || !values.terms}
            size="large"
          >
            LOG IN
          </Button>

          <Box height={12} />

          <Typography fontWeight={500} fontSize={11}>
            Har du ikke en bruger?{" "}
            <Link
              component={RouterLink}
              to="/auth/signup"
              onClick={handleCloseModal}
            >
              Opret en her
            </Link>
          </Typography>

          <Box height={12} />

          <FormControlLabel
            control={
              <Checkbox
                name="terms"
                color="primary"
                required={true}
                onChange={(e) => {
                  if (e.target.checked) {
                    setFieldValue("terms", true);
                  } else {
                    setFieldValue("terms", false);
                  }
                }}
              />
            }
            label={
              <Typography fontWeight={500} fontSize={12}>
                Jeg accepterer{" "}
                <a
                  target="_blank"
                  href="https://nextkey.dk/vilkaar/"
                  style={{ color: "inherit" }}
                  rel="noreferrer"
                >
                  vilkår og betingelser
                </a>
              </Typography>
            }
          />
        </form>
      )}
    </Formik>
  );
}

export default LoginForm;
