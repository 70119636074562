import { Box, Mark, Slider, Typography } from "@mui/material";
import { rgba } from "polished";
import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { AnswerRangeType, QuestionType } from "../types";
import useProfile from "../useProfile";
import _ from "lodash";
import { Star } from "@mui/icons-material";

const StyledSlider = styled(Slider)`
  margin-top: 16px;

  .MuiSlider-valueLabel {
    padding: 0;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    top: -5px;
    font-size: 14px;
    font-weight: 600;
    background-color: ${(props) =>
      rgba(props.theme.palette.secondary.main, 0.1)};
    color: ${(props) => props.theme.palette.primary.main};
    &:before {
      display: none;
    }
  }
  .MuiSlider-thumb {
    width: 16px;
    height: 16px;
  }
  .MuiSlider-rail {
    opacity: 0.1;
  }
  .MuiSlider-mark {
    opacity: 0.25;
  }
  .MuiSlider-markLabel {
    opacity: 0.75;
    font-size: 14px;
  }
`;

type FieldSliderProps = {
  question: QuestionType;
};

const FieldSlider: React.FC<FieldSliderProps> = ({ question }) => {
  const [marks, setMarks] = useState<Mark[]>([]);
  const { update } = useProfile();

  const updateRef = useRef(
    _.debounce(async (data: AnswerRangeType) => {
      // TODO: compare with previous data.
      await update([data]);
    }, 1000)
  );

  useEffect(() => {
    // set initial selected.
    let _marks = [];
    if (question.MaxValue > question.MinValue && question.Steps) {
      for (
        let x = question.MinValue + question.Steps;
        x < question.MaxValue;
        x += question.Steps
      ) {
        _marks.push({ value: x });
      }
    }
    setMarks(_marks);
  }, [question]);

  const handleChange = (value: number) => {
    updateRef.current.cancel();
    const data: AnswerRangeType = {
      ID: Number(question.ID),
      Weight: value,
    };
    updateRef.current(data);
  };

  return (
    <>
      {/* title and favorite icon */}
      <Box mb={3} sx={{ display: "flex", alignItems: "center" }}>
        <Box mr={1} lineHeight={1}>
          {/* favorite icon button */}
          <Star
            sx={{
              color: "#ffd600",
            }}
          />
        </Box>

        <Typography fontWeight={500} display="inline-block">
          {question.Prefix}
        </Typography>
      </Box>

      {/* slider */}
      <StyledSlider
        defaultValue={Number(question.Value)}
        onChangeCommitted={(
          event: React.SyntheticEvent | Event,
          value: number | Array<number>
        ) => {
          if (typeof value === "number") {
            handleChange(value);
          }
        }}
        valueLabelDisplay="on"
        step={1}
        marks={[
          {
            value: question.MinValue,
            label: question.MinValue,
          },
          ...marks,
          {
            value: question.MaxValue,
            label: question.MaxValue,
          },
        ]}
        min={question.MinValue}
        max={question.MaxValue}
        color="secondary"
      />
    </>
  );
};

export default FieldSlider;
