import { IconButton } from "@mui/material";
import React, { useState } from "react";
import ModalImageSlider from "../../../components/ModalImageSlider";
import { UnitImageType } from "../type";
import { ReactComponent as FloorplanIcon } from "../../../assets/Icons/Floorplan.svg";

const Floorplan: React.FC<{ floorplans: UnitImageType[] }> = ({
  floorplans,
}) => {
  const [open, setOpen] = useState(false);
  const [active, setActive] = useState(0);

  const handleOpen = (ind: number) => {
    setOpen(true);
    setActive(ind);
  };
  const handleClose = () => setOpen(false);
  return (
    <>
      <IconButton
        onClick={() => {
          handleOpen(0);
        }}
        style={{ backgroundColor: "#F1EEE7" }}
        sx={{
          mb: 1.5,
          borderRadius: 0,
        }}
      >
        <FloorplanIcon
          style={{
            height: "1em",
          }}
        />
      </IconButton>
      <ModalImageSlider
        open={open}
        handleClose={handleClose}
        images={floorplans.map((image) => image.Url)}
        active={active}
      />
    </>
  );
};

export default Floorplan;
