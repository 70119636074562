import { AxiosResponse } from "axios";
import { useCallback, useEffect, useState } from "react";
import useAppDispatch from "../../redux/useAppDispatch";
import useAppSelector from "../../redux/useAppSelector";
import axios from "../../utils/api";
import { useAuth } from "../auth";
import useLog, { Action } from "../logs/useLogs";
import {
  fetchData as sliceFetchdata,
  selectUnitList,
  selectUnitStatus,
} from "./slice";
import { LikeDislikeType } from "./type";

const useUnits = (load: boolean = false) => {
  const { isAuthenticated } = useAuth();
  const [unseen, setUnseen] = useState<number>(0);
  const [isLoading, setLoading] = useState<boolean>(false);
  const { send: sendLog } = useLog();

  const units = useAppSelector(selectUnitList);
  const status = useAppSelector(selectUnitStatus);
  const dispatch = useAppDispatch();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fetchData = useCallback(async (force: boolean = false) => {
    await dispatch(sliceFetchdata(force));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      fetchData(load);
    }
    const interval = setInterval(() => {
      if (isAuthenticated) {
        fetchData(load);
      }
    }, 60000);
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  useEffect(() => {
    setLoading(status === "loading" && units.length === 0);
  }, [status, units]);

  useEffect(() => {
    if (units.length) setUnseen(units.filter((unit) => unit.IsNewMatch).length);
  }, [units]);

  const bookDisplay = useCallback(
    async (id: number): Promise<boolean> => {
      try {
        const response = await axios.put(
          `/user/matchedunits/${id.toString()}`,
          { BookDisplay: 1 }
        );
        if (response.status === 200) {
          sendLog(Action.UNIT_BOOK_DISPLAY, {}, id)?.then(() => {
            fetchData(true);
          });
          return true;
        }
        return false;
      } catch (error) {
        return false;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  // handle like/dislike.
  const likeDislike = useCallback(
    async (id: number, data: LikeDislikeType): Promise<boolean> => {
      try {
        const response = await axios.put(
          `/user/matchedunits/${id.toString()}`,
          data
        );
        if (response.status === 200) {
          // send log.
          if (data.UserLikes === 1) {
            sendLog(Action.UNIT_LIKED, {}, id)?.then(() => {
              fetchData(true);
            });
          }
          if (data.UserDisLikes === 1) {
            sendLog(Action.UNIT_DISLIKED, {}, id)?.then(() => {
              fetchData(true);
            });
          }

          return true;
        }
        return false;
      } catch (error) {
        return false;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const removeUnit = async (
    id: number
  ): Promise<{ Status: string } | undefined> => {
    try {
      const response = await axios.put<
        any,
        AxiosResponse<{ Status: string }, any>,
        any
      >(`/user/matchedunits/${id.toString()}`, {
        ShowToUser: 0,
      });
      fetchData(true);
      return response.data;
    } catch (error) {}
  };

  const sendLogViewed = useCallback(
    (id: number) => {
      sendLog(Action.UNIT_VIEWED, {}, id)?.then(() => {
        fetchData(true);
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return {
    units,
    unseen,
    isLoading,
    likeDislike,
    bookDisplay,
    fetchData,
    sendLogViewed,
    removeUnit,
  };
};

export default useUnits;
