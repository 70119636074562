import { Close } from "@mui/icons-material";
import { Box, IconButton, Modal } from "@mui/material";
import React from "react";
import useAppDispatch from "../../../redux/useAppDispatch";
import useAppSelector from "../../../redux/useAppSelector";
import { closeSignupModal, selectOpenSignupModal } from "../slice";
import RegisterContent from "./RegisterContent";

const RegisterModal = () => {
  const isOpen = useAppSelector(selectOpenSignupModal);
  const dispatch = useAppDispatch();

  const handleClose = () => dispatch(closeSignupModal());

  return (
    <Modal open={isOpen} onClose={handleClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 1000,
          maxWidth: "100%",
        }}
      >
        <IconButton
          sx={{
            position: "absolute",
            right: "-20px",
            top: "-20px",
            backgroundColor: "#091f2c",
            color: "#ffffff",
            "&:hover": {
              backgroundColor: "#204355 !important",
            },
          }}
          onClick={handleClose}
        >
          <Close />
        </IconButton>
        <RegisterContent />
      </Box>
    </Modal>
  );
};

export default RegisterModal;
