import { useEffect, useState } from "react";
import axios from "../../../utils/api";
import { GreetingType } from "../types";

const useWelcome = (load: boolean = false) => {
  const [greeting, setGreeting] = useState<GreetingType>({
    Title: "",
    Text: "",
  });
  const [greetingIsLoading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (load) {
      const getGreeting = async () => {
        try {
          setLoading(true);
          const response = await axios.get("/user/greetings/");
          if (response.status === 200 && response.data && response.data) {
            setGreeting(response.data);
          }
        } catch (error) {
        } finally {
          setLoading(false);
        }
      };
      getGreeting();
    }
  }, [load]);

  // TODO: handle like/dislike.

  return { greeting, greetingIsLoading };
};

export default useWelcome;
