import axios from "../../utils/api";
import { useEffect } from "react";
import useAppDispatch from "../../redux/useAppDispatch";
import useAppSelector from "../../redux/useAppSelector";
import {
  selectUserInfo,
  selectUserAvatar,
  forceAvatarCache as disForceAvatarCache,
  fetchInfo as fetchUserInfo,
  clear as clearInfo,
} from "./userSlice";
import { UserInfoType } from "./types";
import { AxiosResponse } from "axios";
import useLog, { Action } from "../logs/useLogs";

const useUser = (fetch: boolean = false) => {
  const info = useAppSelector(selectUserInfo);
  const avatar = useAppSelector(selectUserAvatar);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (fetch) {
      fetchInfo(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchInfo = async (force: boolean = false) => {
    const info = await dispatch(fetchUserInfo(force));
    return info.payload;
  };

  const clear = async () => {
    dispatch(clearInfo());
  };

  const { send: sendLog } = useLog();

  const updateInfo = async (data: UserInfoType): Promise<boolean> => {
    const response = await axios.put<
      any,
      AxiosResponse<any, any>,
      UserInfoType
    >("/user/", data);

    const { Status } = response.data;
    if (Status !== "OK") {
      throw new Error(Status);
    } else {
      // fetch new user info.
      await fetchInfo(true).then(() => {
        if (
          data.FirstName &&
          data.LastName &&
          (data.FirstName !== info?.FirstName ||
            data.LastName !== info?.LastName)
        ) {
          sendLog(Action.CHANGED_NAME, {}, undefined);
        }
        if (data.Email && data.Email !== info?.Email) {
          sendLog(Action.CHANGED_EMAIL, {}, undefined);
        }
        if (
          data.Notifications !== undefined &&
          data.Notifications !== info?.Notifications
        ) {
          if (data.Notifications) {
            sendLog(Action.NOTIFICATIONS_ON, {}, undefined);
          } else {
            sendLog(Action.NOTIFICATIONS_OFF, {}, undefined);
          }
        }
      });
      return true;
    }
  };

  const forceAvatarCache = () => {
    dispatch(disForceAvatarCache());
  };

  return { info, fetchInfo, clear, updateInfo, avatar, forceAvatarCache };
};

export default useUser;
