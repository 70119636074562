import { Paper } from "@mui/material";
import React from "react";
import styled from "styled-components";

const Image = styled("img")`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

type ImageBannerProps = {
  img: string;
};

const ImageBanner: React.FC<ImageBannerProps> = (props) => {
  return (
    <Paper
      elevation={10}
      sx={{
        position: "relative",
        overflow: "hidden",
        pt: "15%",
        height: 250,
        borderRadius: 1.5,
      }}
    >
      <Image src={props.img} alt="" />
    </Paper>
  );
};

export default ImageBanner;
