import { CardMedia, Card, CardContent, Typography, Grid } from "@mui/material";
import photo1 from "../assets/images/step1.png";
import photo2 from "../assets/images/step2.png";
import photo3 from "../assets/images/step3.png";
import photo4 from "../assets/images/step4.png";

type CardProps = {
  step: number;
  title: string;
  paragraph: string;
};

const CardStep = ({ step, title, paragraph }: CardProps) => {
  const Step = step;
  const Title = title;
  const Paragraph = paragraph;
  return (
    <Grid item lg={4} xl={3} xs={12} sx={{ mb: 4 }}>
      <Typography
        gutterBottom
        variant="h5"
        component="div"
        sx={{
          fontSize: "16px",
          fontStyle: "normal",
          fontFamily: "Raleway",
          color: "#9E652E",
          fontWeight: 500,
          mb: 3,
          letterSpacing: "0.1em",
          textAlign: "left",
        }}
      >
        STEP {Step}
      </Typography>
      <Card sx={{ maxWidth: 345, height: "100%" }}>
        <CardMedia
          component="img"
          image={
            Step === 1
              ? photo1
              : Step === 2
              ? photo2
              : Step === 3
              ? photo3
              : photo4
          }
          sx={{ height: 125 }}
        />
        <CardContent>
          <Typography
            gutterBottom
            fontFamily={"helvetica"}
            component="div"
            sx={{
              fontSize: "24px",
              fontFamily: "Helvetica",
              fontWeight: 400,
              lineHeight: "33px",
              letterSpacing: "0px",
              textAlign: "left",
            }}
          >
            {Title}
          </Typography>
          <Typography
            sx={{
              fontStyle: "Normal",
              fontFamily: "Poppins",
              textAlign: "left",
              whiteSpace: "pre-wrap",
            }}
          >
            {Paragraph}
          </Typography>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default CardStep;
