import { Alert } from "@mui/material";
import jsonp from "jsonp";
import {
  createContext,
  ReactNode,
  useEffect,
  useReducer,
  useState,
} from "react";
import axios from "../../utils/api";
import useLog, { Action } from "../logs/useLogs";
import { useProfile } from "../profile";
import useUser from "../profile/useUser";
import LoginModal from "./components/LoginModal";
import RegisterModal from "./components/RegisterModal";
import { ActionMap, AuthContextType, AuthState } from "./types";
import { setSession } from "./utils";

const INITIALIZE = "INITIALIZE";
const SIGN_IN = "SIGN_IN";
const SIGN_OUT = "SIGN_OUT";
const SIGN_UP = "SIGN_UP";

type AuthActionTypes = {
  [INITIALIZE]: {
    isAuthenticated: boolean;
  };
  [SIGN_IN]: undefined;
  [SIGN_OUT]: undefined;
  [SIGN_UP]: undefined;
};

const initialState: AuthState = {
  isAuthenticated: false,
  isInitialized: false,
};

const AuthReducer = (
  state: AuthState,
  action: ActionMap<AuthActionTypes>[keyof ActionMap<AuthActionTypes>]
) => {
  switch (action.type) {
    case INITIALIZE:
      return {
        isAuthenticated: action.payload.isAuthenticated,
        isInitialized: true,
      };
    case SIGN_IN:
      return {
        ...state,
        isAuthenticated: true,
      };
    case SIGN_OUT:
      return {
        ...state,
        isAuthenticated: false,
      };
    case SIGN_UP:
      return {
        ...state,
        isAuthenticated: true,
      };

    default:
      return state;
  }
};

const AuthContext = createContext<AuthContextType | null>(null);

function AuthProvider({ children }: { children: ReactNode }) {
  const [state, dispatch] = useReducer(AuthReducer, initialState);
  const { fetchInfo, clear } = useUser();
  const { fetchData, clear: clearProfile } = useProfile();
  const [linkSent, setLinkSent] = useState(false);
  const { send: sendLog } = useLog();

  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = window.localStorage.getItem("accessToken");
        if (accessToken) {
          setSession(accessToken);

          const user = await fetchInfo(true);

          if (user) {
            await fetchData();

            dispatch({
              type: INITIALIZE,
              payload: {
                isAuthenticated: true,
              },
            });
            return;
          }
        }
      } catch (err) {}

      dispatch({
        type: INITIALIZE,
        payload: {
          isAuthenticated: false,
        },
      });
    };
    initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const signIn = async (email: string, password: string) => {
    const response = await axios.post("/user/token/", {
      User: email,
      Password: password,
    });

    const { Token } = response.data;

    if (Token) {
      setSession(Token);
      sendLog(Action.LOGIN, {}, undefined)?.then(() => {
        fetchData(true);
      });
      dispatch({
        type: SIGN_IN,
      });
      await fetchInfo(true);
      await fetchData();
      setLinkSent(false);
    } else {
      const { Status, StatusCode, ActivationToken } = response.data;
      setSession(ActivationToken);
      throw {
        message: Status,
        statusCode: StatusCode,
      };
    }
  };

  const ActivationMail = async () => {
    const data = await axios.post("user/?action=activationMail");
    setSession(null);
    return data;
  };

  const signOut = async () => {
    await sendLog(Action.LOGOUT, {}, undefined)?.then(() => {
      fetchData(true);
    });
    await axios.delete("/user/token/");
    setSession(null);
    dispatch({ type: SIGN_OUT });
    clear();
    clearProfile();
  };

  const signUp = async (
    email: string,
    password: string,
    username: string,
    firstname: string,
    lastname: string,
    phone: string
  ) => {
    // handle signup.
    const response = await axios.post("/user/", {
      Email: email,
      Username: username,
      Password: password,
      FirstName: firstname,
      LastName: lastname,
      Telephone: phone,
    });

    const { Status, Link } = response.data;
    if (Status !== "OK") {
      throw new Error(Status);
    } else {
      setLinkSent(true);
      // console.log(response.data);
      // const url =
      //   "https://nextkey.us6.list-manage.com/subscribe/post?u=4a23a483bc7b2df3b1f2929cc&id=6b09b54319";
      // let _data: any = {
      //   FNAME: firstname,
      //   LNAME: lastname,
      //   EMAIL: email,
      //   PHONE: phone,
      //   LINK: Link,
      // };
      // const _values = Object.keys(_data)
      //   .map((key) => `${key}=${encodeURIComponent(_data[key])}`)
      //   .join("&");
      // const action = `${url}&${_values}`;
      // jsonp(action, { param: "c" }, (err, data) => {});
    }
  };

  const checkAuth = async (): Promise<boolean> => {
    const user = await fetchInfo(true);
    if (user) {
      return true;
    }

    return false;
  };

  const resetPassword = (email: string) => {
    // TODO: handle reset password.
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        checkAuth,
        signIn,
        signOut,
        signUp,
        resetPassword,
        ActivationMail,
      }}
    >
      <>
        {linkSent && (
          <>
            <Alert severity="warning">
              Du har fået tilsendt et aktiveringslink til din mail.
            </Alert>
          </>
        )}
        {children}
        <LoginModal />
        <RegisterModal />
      </>
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
